/**
 * Created by Ahmed Alaya on 15/12/2017.
 */
var SAFE_TO_LOAD_SERVICES = [

    {name: "SafeToLoadUserSrv", path: "user"},
    {name: "SafeToLoadCompanySrv", path: "company"},
    {name: "SafeToLoadRoleSrv", path: "role"},
    {name: "SafeToLoadAssetSrv", path: "asset"},
    {name: "SafeToLoadDriverSrv", path: "driver"},
    {name: "SafeToLoadSiteSrv", path: "site"},
    {name: "SafeToLoadSubConstractorSrv", path: "subconstractor"},
    {name: "SafeToLoadCheckListSrv", path: "dailycheck"},
    {name: "SafeToLoadVehicleCtrlSheetSrv", path: "ctrlsheet"},
    {name: "SafeToLoadDriverSafetyVisitSrv", path: "driversafety"},
    {name: "SafeToLoadCompanySrv", path: "company"},
    {name: "SafeToLoadAssetTypeSrv", path: "assettype"},
    {name: "SafeToLoadCountrySrv", path: "country"}

];
/**
 * ad constant service for further use
 */
angular.module("app.services").constant("SAFE_TO_LOAD_SERVICES", SAFE_TO_LOAD_SERVICES);

// create services
SAFE_TO_LOAD_SERVICES.forEach(function (val) {
  angular.module("app.services").factory(val.name, ["AbstractApiSrv", "CONFIG", function (AbstractApiSrv, CONFIG) {
    var service = new AbstractApiSrv(val);
    service.setApiUri(CONFIG.API_URI);
    return service;
  }]);
});

