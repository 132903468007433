/**
 * Created by Ahmed Alaya on 15/12/2017.
 */

angular.module("app.services").service('SafeToLoadHelperSrv', function ($http, $rootScope, $window, AbstractApiSrv, SAFE_TO_LOAD_SERVICES, $injector, Upload, $timeout) {
  function SafeToLoadHelperSrv() {

  }
  SafeToLoadHelperSrv.prototype.getEnums = function (success, error) {
    $http.get($rootScope.config.API_URI + '/enums').then(function (response) {
            // this callback will be called asynchronously
            // when the response is available
      if (response && response.data && response.data.success) {
        success(response.data.result);
      } else if (!response.data.success && response.data.error) {
        success(response.data);
      } else {
        error(response);
      }
    }, function (data) {
      error(data);
    });
  };
    //
    //
    //        function SafeToLoadHelperSrv(){
    //
    //        }
    //
    // SafeToLoadHelperSrv. prototype.setLoggedInStatus=function(status){
    //                this. loggedIn = status;
    //        }
    // SafeToLoadHelperSrv.prototype.login=function(user,success,error){
    //
    //
    //            var  self=this;
    //                $http.post($rootScope.config.API_URI  + '/' + "login" , user).then(function (response) {
    //                    // this callback will be called asynchronously
    //                    // when the response is available
    //                    if (response && response.data && response.data.success) {
    //                        $window.sessionStorage.token = response.data.result.token;
    //
    //
    //                        $rootScope.SESSION = response.data.result;
    //                        $window.sessionStorage.SESSION= JSON.stringify(response.data.result);
    //                        self.loggedIn = true;
    //                        success(response.data);
    //
    //                    }
    //                    else if (!response.data.success && response.data.error) {
    //
    //                        success(response.data)
    //                    }
    //                    else {
    //
    //                        error(response);
    //                    }
    //
    //                })
    //            }
    //
    //
    // SafeToLoadHelperSrv.prototype.logout=function(success){
    //    delete $window.sessionStorage.token;
    //    delete $window.sessionStorage.SESSION;
    //    delete $window.sessionStorage.plateformToken;
    //    delete $window.sessionStorage.plateformSession;
    //
    //    if (success) success()
    // }
    // SafeToLoadHelperSrv.prototype.getEnums=function(success, error){
    //
    //    $http.get($rootScope.config.API_URI+'/enums').then(function(response) {
    //        // this callback will be called asynchronously
    //        // when the response is available
    //        if (response && response.data && response.data.success){
    //            success(response.data.result);
    //        }
    //        else if (!response.data.success && response.data.error){
    //            success(response.data)
    //        }
    //        else{
    //
    //            error(response);
    //        }
    //
    //
    //
    //    },function(data) {
    //        error(data)
    //
    //    });
    // };
    //
    // SafeToLoadHelperSrv.prototype.uploadFiles=function(files ,success,error,progress){
    //    //open progress bar
    //    if (files && files.length) {
    //
    //        Upload.upload({
    //            url:$rootScope.config.API_URI  + '/' + "document"  ,
    //            data: {
    //                file: files
    //            }
    //        }).then(function (response) {
    //            $timeout(function () {
    //
    //            });
    //            success(response.data);
    //        }, function (response) {
    //            if (response.status > 0) {
    //
    //                error(response);
    //            }else{
    //                success(response);
    //            }
    //
    //
    //        }, function (evt) {
    //            progress(evt);
    //
    //        });
    //    }
    //
    // }
    //
  return new SafeToLoadHelperSrv();
});
