/**
 * Project: SafeToLoad
 * Created by  YOUSFI houcine 18/12/2017.
 */
angular.module('app').controller('statControlCtrl', function ($scope, $rootScope,$timeout,Excel, $state,ngDialog,  SafeToLoadVehicleCtrlSheetSrv,SafeToLoadAssetSrv, constantSrv, Notification, $translate) {
    $scope.assetControlProperties = constantSrv.assetControlProperties();
    $scope.assetRequiredControlProperties = constantSrv.assetRequiredControlProperties();
    $scope.statControl = {}
    $scope.statControl.type="ASSETS"
    $scope.assetStatControlSheetList=[]
    $scope.assetsMap = {};
    $scope.moment = moment;

    SafeToLoadAssetSrv.query({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.assets = response.result;
            angular.forEach($scope.assets, function (asset) {
                $scope.assetsMap[asset._id] = asset.name;

            });

        }
    });

    var now = moment().clone();
    var today = moment().clone().startOf('day');
    $scope.startRes = today;
    $scope.endRes = now;
    $scope.pickerStart = {
        format: "DD/MM/YYYY",
        init: today,
        position: "top left",
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: true,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.startRes = date;
            $scope.pickerEnd.minDate = date;
        }
    };


    $scope.pickerEnd = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: true,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.endRes = date;
            $scope.pickerStart.maxDate = date;
        }
    };
    $scope.getQuery = function (assetIds) {
        return {
            aggregate: [
                {

                    "$match": {
                        "$and": [
                            {
                                "_asset": {
                                    $in: assetIds.map(function (elem) {
                                        return "#OBJID#" + elem + "#OBJIDEND#";
                                    })
                                }
                            },
                            {
                                "creation_dt": {
                                    "$gte" : "#ISODATE#"+ $scope.startRes.clone().format('YYYY-MM-DD') +"T00:00:00.000Z#ISODATEEND#",
                                    "$lte" : "#ISODATE#"+ $scope.endRes.clone().add(1, "days").format('YYYY-MM-DD') +"T00:00:00.000Z#ISODATEEND#"

                                }
                            }
                        ]


                    }


                },

                {$sort: {creation_dt: -1}},
                {
                    $project: {
                        _asset: 1,
                        document: "$$ROOT"
                    }
                },
                {
                    $group: {
                        _id: '$_asset',
                        data: {
                            $push: {
                                properties: "$document"

                            }

                        }
                    }
                }


            ]
        };
    };
    $scope.loadHistoAssetControl = function () {
        if ($scope.statControl.asset) {
            var assetIds = [$scope.statControl.asset._id ]
            SafeToLoadVehicleCtrlSheetSrv.agg($scope.getQuery(assetIds), 10000, 0, function (response) {
                if (response.success) {
                    $scope.assetStatControlSheetList = response.result;

                }
            });
        }
        else{
            $scope.assetStatControlSheetList=[]

        }
    };

    $scope.loadHistoSupplierControl = function () {

        var supplierAssetIds =[]
        if ($scope.statControl.supplier) {
            var assetQuery = {
                query: {
                    _sub_constractor: $scope.statControl.supplier._id,
                }

            };
            SafeToLoadAssetSrv.query(assetQuery, 10000, 0, function (response) {
                if (response.success) {
                    var supplierAssets = response.result;
                    if(supplierAssets  && supplierAssets.length>0){
                        supplierAssetIds=supplierAssets.map(function(asset){
                            return asset._id;
                        })

                        console.log("supplierAssetIds ",supplierAssetIds)

                        if (supplierAssetIds.length>0) {
                            SafeToLoadVehicleCtrlSheetSrv.agg($scope.getQuery(supplierAssetIds), 10000, 0, function (res) {
                                if (res.success) {
                                    $scope.assetStatControlSheetList = res.result;


                                }
                            });
                        }


                    }


                }
            });
        }
        else{
            $scope.assetStatControlSheetList=[]

        }
    };

    $scope.loadHistoSiteControl = function () {

        var siteAssetIds =[]
        if ($scope.statControl.site) {
            var assetQuery = {
                query: {
                    _site: $scope.statControl.site._id,
                }

            };
            SafeToLoadAssetSrv.query(assetQuery, 10000, 0, function (response) {
                if (response.success) {
                    var siteAssets = response.result;
                    if(siteAssets  && siteAssets.length>0){
                        siteAssetIds=siteAssets.map(function(asset){
                            return asset._id;
                        })


                        if (siteAssetIds.length>0) {
                            SafeToLoadVehicleCtrlSheetSrv.agg($scope.getQuery(siteAssetIds), 10000, 0, function (res) {
                                if (res.success) {
                                    $scope.assetStatControlSheetList = res.result;


                                }
                            });
                        }


                    }


                }
            });
        }
        else{
            $scope.assetStatControlSheetList=[]

        }
    };
    $scope.loadHistoControl = function () {

        if($scope.statControl.type=="ASSETS"){
            $scope.loadHistoAssetControl()
        }
        else if($scope.statControl.type=="SUPPLIERS"){
            $scope.loadHistoSupplierControl();
        }
        else if($scope.statControl.type=="SITES"){
            $scope.loadHistoSiteControl();
        }
    };


    $scope.ExportStatControl = function () {
        // ex: '#my-table'
       // $scope.exportMode=true;
        var nom='ControlSheet_statics';
        var tableId='#assetStatControlSheetTable';

        $timeout(function () {

            $scope.exportHref = Excel.tableToExcel(tableId,nom,true);
        }, 100);

        $timeout(function () {
           // location.href = $scope.exportHref;
            var a = document.createElement('a');
            a.href = $scope.exportHref;
            a.download = nom+'-'+moment().format('YYYY-MM-DD-HHmmSS')+'.xls';
            a.click();
        }, 100);

        $timeout(function () {
          //  $scope.exportMode=false;
        },150); // trigger download






    };



});
