angular.module('app').controller('adminSiteAddCtrl', function ($scope, $rootScope, $state, $translate, SafeToLoadSiteSrv, ngDialog, Notification) {
    $scope.site  = {};
    $scope.addNewChecker = function () {
        ngDialog.open({
            template: 'app/commons/dialogs/siteChecker.dlg.tpl.html',
            controller: 'addSiteCheckerDlgCtrl',
            scope:$scope
        });
    };

    /*Remove checkers*/
    $scope.deleteChecker = function(checker){
        var index = $scope.site.checkers.indexOf(checker);
        if (index > -1) {
            $scope.site.checkers.splice(index, 1);
        }
    };

    $scope.save = function () {
        //$scope.site._company_owner = $rootScope.SESSION.user._company_owner._id;
        SafeToLoadSiteSrv.add($scope.site, function (data) {
            if (data.success) {
                Notification.success({
                    title : $translate.instant("ADD"),
                    message : $translate.instant("ALERT_SITE_ADDED",{name:$scope.site.name})
                });

                $state.go('app.admin.sites');

            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.site.name +"!"
                });
                $scope.site  = {};
            }
        }, function (data) {
            console.log(data);
        });
    }

})