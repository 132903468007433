angular.module("app").controller('adminCtrl', function ($scope, $rootScope, constantSrv, $state, SafeToLoadRoleSrv, SafeToLoadCountrySrv, SafeToLoadCompanySrv, SafeToLoadSiteSrv, SafeToLoadSubConstractorSrv, SafeToLoadHelperSrv) {
    SafeToLoadRoleSrv.query({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.roles = response.result;
        }
    });
    SafeToLoadCompanySrv.query({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.companies = response.result;
        }
    });
    SafeToLoadSiteSrv.query({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.sites = response.result;
        }
    });
    SafeToLoadSubConstractorSrv.query({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.subConstractors = response.result;
        }
    });

    SafeToLoadCountrySrv.query({}, 1000, 0, function (res) {
        $scope.countries = res.result;
    });

});