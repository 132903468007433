angular.module('app').controller('adminCompanyCtrl', function ($scope, $rootScope, $state, $translate, SafeToLoadCompanySrv, ngDialog, Notification) {
    $scope.companies = {};
    $scope.itemsByPage = 5;
    $scope.search = {};

    $scope.loadCompanies = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();

            return;
        }
        var start = 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
        var number = $scope.itemsByPage;
        var query = {
            options: {populate:
                    [
                        {
                            path: '_ctry',
                            select: 'name'

                        }
                    ]

            }
        };
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        if (tableState.sort && tableState.sort.predicate) {
            query.options.sortBy = {};

            var predicate = tableState.sort.predicate;
            query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }
        var requery = {};
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.name) {
                requery.name = {$regex: tableState.search.predicateObject.name, $options: "i"};
            }
        }
        if ($scope.search) {
            if ($scope.search.country) {
                requery._ctry = $scope.search.country.code;
            }
        }
        query.query = requery;
        SafeToLoadCompanySrv.query(query, number, start, function (data) {
            if (data.success) {
                $scope.companies = data.result;
                $scope.companyCount = data.total_count;
                tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                tableState.pagination.totalItemCount = data.total_count;
                $scope.tableState = tableState;
            } else {
                console.error(data.error);
            }
        }, function (data) {
            console.log(data);
        });
    };
    /* delete company*/
    $scope.remove = function (company) {
        ngDialog.openConfirm({template: 'app/commons/dialogs/confirm.dlg.html', overlay: true, showClose: false, controller: ['$scope', function ($scope){
                // controller logic
                $scope.title = $translate.instant("COMPANY_DELETE_CONFIRM_TITLE");
                $scope.message = $translate.instant("COMPANY_DELETE_CONRIFM_MESSAGE", {name: company.name});
            }]}).then(function () {
            SafeToLoadCompanySrv.delete(company._id, function (data) {
                if (data.success) {
                    Notification($translate.instant("ALERT_COMPANY_DELETED", {name: company.name}));
                    $scope.loadCompanies();
                    $scope.tableState.pagination.start = 0;
                } else {
                    console.log(data.error);
                }
            }, function (err) {

            });
        }, function (reason) {

        });
    };
});
