
angular.module("app").controller('addSiteCheckerDlgCtrl',function ($rootScope,$scope,Notification,$translate,ngDialog) {
    $scope.addChecker = function(checker){
        if(checker){
            if($scope.site.checkers && $scope.site.checkers.length>0){
                if ($scope.site.checkers.indexOf(checker) === -1) {
                    $scope.site.checkers.push(checker);
                    $scope.closeThisDialog()
                }else{
                    Notification.error($translate.instant("EMAIL_ALREADY_EXIST"));
                }
            }else{
                $scope.site.checkers.push(checker);
                $scope.closeThisDialog()
            }
        }else{
            Notification.error($translate.instant("NO_RECIPIENT"));
        }
        //$scope.checkers.sendParams.recipient =''
    };
    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

});

