angular.module('app').controller('adminSiteEditCtrl', function ($scope, $rootScope, $state, $translate, SafeToLoadSiteSrv, $stateParams,ngDialog, Notification) {

    $scope.site = {};
    $scope.addNewChecker = function () {
        ngDialog.open({
            template: 'app/commons/dialogs/siteChecker.dlg.tpl.html',
            controller: 'addSiteCheckerDlgCtrl',
            scope:$scope
        });
    };

    /*Remove checkers*/
    $scope.deleteChecker = function(checker){
        var index = $scope.site.checkers.indexOf(checker);
        if (index > -1) {
            $scope.site.checkers.splice(index, 1);
        }
    };
    var query = {
        query: {
            _id: $stateParams.siteId
        },
        options: {
            populate: [
                {
                    path: '_company_owner',
                    select: 'name'
                }
            ]

        }
    };
    SafeToLoadSiteSrv.query(query, 1, 1, function (response) {
        $scope.site = response.result[0];
    }, function (err) {
        console.log(err);
    });


    $scope.save = function () {
        //$scope.site._company_owner = $rootScope.SESSION.user._company_owner._id;
        SafeToLoadSiteSrv.updateById($scope.site._id,$scope.site, function (data) {
            if (data.success) {
                Notification.success({
                    title : $translate.instant("UPDATE"),
                    message : $translate.instant("ALERT_SITE_UPDATED",{name:$scope.site.name})
                });
                $state.go('app.admin.sites');
                $scope.site  = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.site.name +"!"
                });
                $scope.site  = {};
            }
        }, function (data) {
            console.log(data);
        });
    }

});