/**
 * Project: SafeToLoad
 * Created by  YOUSFI houcine 18/12/2017.
 */
angular.module('app').controller('statDriverCtrl', function ($scope, $rootScope, $timeout, Excel, $state,ngDialog,constantSrv,  SafeToLoadDriverSafetyVisitSrv,SafeToLoadDriverSrv,  Notification, $translate) {
    $scope.statDriver = {}
    $scope.statDriverList=[]
    $scope.statDriver.type="DRIVERS"
    $scope.driversMap = {};
    $scope.moment = moment;

    var now = moment().clone();
    var today = moment().clone().startOf('day');
    $scope.driverProperties = constantSrv.driverSafetyProperties();
    SafeToLoadDriverSrv.query({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.drivers = response.result;
            angular.forEach($scope.drivers, function (driver) {
                $scope.driversMap[driver._id] = driver.last_name+" "+driver.first_name;

            });

        }
    });
    $scope.startRes = today;
    $scope.endRes = now;
    $scope.pickerStart = {
        format: "DD/MM/YYYY",
        init: today,
        position: "top left",
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.startRes = date;
            $scope.pickerEnd.minDate = date;
        }
    };


    $scope.pickerEnd = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.endRes = date;
            $scope.pickerStart.maxDate = date;
        }
    };


    $scope.getQuery = function (driverIds) {
        return {
            aggregate: [
                {

                    "$match": {
                        "$and": [
                            {
                                "_driver": {
                                    $in: driverIds.map(function (elem) {
                                        return "#OBJID#" + elem + "#OBJIDEND#";
                                    })
                                }
                            },
                            {
                                "creation_dt": {
                                    "$gte" : "#ISODATE#"+ $scope.startRes.clone().format('YYYY-MM-DD') +"T00:00:00.000Z#ISODATEEND#",
                                    "$lte" : "#ISODATE#"+ $scope.endRes.clone().add(1, "days").format('YYYY-MM-DD') +"T00:00:00.000Z#ISODATEEND#"

                                }
                            }
                        ]


                    }


                },

                {$sort: {creation_dt: -1}},
                {
                    $project: {
                        _driver: 1,
                        document: "$$ROOT"
                    }
                },
                {
                    $group: {
                        _id: '$_driver',
                        data: {
                            $push: {
                                properties: "$document"

                            }

                        }
                    }
                }


            ]
        };
    };
    $scope.loadHistoDriverSecurity= function () {
        if ($scope.statDriver.driver) {
            var driverIds = [$scope.statDriver.driver._id ]

            SafeToLoadDriverSafetyVisitSrv.agg($scope.getQuery(driverIds), 10000, 0, function (response) {
                if (response.success) {
                    $scope.statDriverList = response.result;

                }
            });
        }
     };

    $scope.loadHistoSupplierDriver = function () {

        var supplierDriverIds =[]
        if ($scope.statDriver.supplier) {
            var driverQuery = {
                query: {
                    _sub_constractor: $scope.statDriver.supplier._id,
                }

            };
            SafeToLoadDriverSrv.query(driverQuery, 10000, 0, function (response) {
                if (response.success) {
                    var supplierDrivers = response.result;
                    if(supplierDrivers  && supplierDrivers.length>0){
                        supplierDriverIds=supplierDrivers.map(function(driver){
                            return driver._id;
                        })


                        if (supplierDriverIds.length>0) {
                            SafeToLoadDriverSafetyVisitSrv.agg($scope.getQuery(supplierDriverIds), 10000, 0, function (res) {
                                if (res.success) {
                                    $scope.statDriverList = res.result;



                                }
                            });
                        }


                    }


                }
            });
        }
        else{
            $scope.statDriverList=[]

        }
    };
    $scope.loadHistoSiteDriver = function () {

        var siteDriversIds =[]

        if ($scope.statDriver.site) {
            var driverQuery = {
                query: {
                    _site: $scope.statDriver.site._id
                }

            };
            SafeToLoadDriverSrv.query(driverQuery, 10000, 0, function (response) {
                if (response.success) {
                    var siteDrivers = response.result;
                    if(siteDrivers  && siteDrivers.length>0){
                        siteDriversIds=siteDrivers.map(function(driver){
                            return driver._id;
                        })
                        if (siteDriversIds.length>0) {
                            SafeToLoadDriverSafetyVisitSrv.agg($scope.getQuery(siteDriversIds), 10000, 0, function (res) {
                                if (res.success) {
                                    $scope.statDriverList = res.result;


                                }
                            });
                        }


                    }


                }
            });
        }
        else{
            $scope.assetStatDailyList=[]

        }
    };
    $scope.loadHistoDriverSafety = function () {

        if($scope.statDriver.type=="DRIVERS"){
            $scope.loadHistoDriverSecurity()
        }
        else if($scope.statDriver.type=="SUPPLIERS"){
            $scope.loadHistoSupplierDriver();
        }
        else if($scope.statDriver.type=="SITES"){
            $scope.loadHistoSiteDriver();
        }
    };

    $scope.ExportStatControl = function () {
        // ex: '#my-table'
        // $scope.exportMode=true;
        var nom='DriverVisit_statics';
        var tableId='#statDriverTable';

        $timeout(function () {

            $scope.exportHref = Excel.tableToExcel(tableId,nom,true);
        }, 100);

        $timeout(function () {
            //location.href = $scope.exportHref;
            var a = document.createElement('a');
            a.href = $scope.exportHref;
            a.download = nom+'-'+moment().format('YYYY-MM-DD-HHmmSS')+'.xls';
            a.click();
        }, 100);

        $timeout(function () {
            //  $scope.exportMode=false;
        },150); // trigger download






    };

});
