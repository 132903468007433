/**
 * Created by Hamdi on 18/12/2017.
 */

angular.module('app').controller('driverSafetyVisitCtrl', function ($scope, $rootScope, $state, $translate, Notification, SafeToLoadUserSrv,SafeToLoadSiteSrv,
                                                                    SafeToLoadDriverSafetyVisitSrv, SafeToLoadDriverSrv, SafeToLoadCompanySrv, ngDialog) {
  $scope.itemsByPage = 5;
  $scope.search = {};

  SafeToLoadCompanySrv.query({}, 100, 1, function (response) {
    if (response.success) {
      $scope.companies = response.result;
    }
  });

  SafeToLoadDriverSrv.query({}, 1000, 1, function (response) {
    if (response.success) {
      $scope.drivers = response.result;
    }
  });
    SafeToLoadSiteSrv.query({}, 1000, 1, function (response) {
        if (response.success) {
            $scope.sites = response.result;
        }
    });
  SafeToLoadUserSrv.query({}, 100, 1, function (response) {
    if (response.success) {
      $scope.users = response.result;
    }
  });

  $scope.loadVisits = function (tableState, ctrl) {
    if (!$scope.stCtrl && ctrl) {
      $scope.stCtrl = ctrl;
    }

    if (!tableState && $scope.stCtrl) {
      $scope.stCtrl.pipe();
      return;
    }
    var start = 0;
    var number = $scope.itemsByPage;

    var options = {
      populate: [
        {
          path: '_company_owner',
          select: 'name'

        },
        {
          path: '_created_by',
          select: 'first_name last_name'
        },
        {
          path: '_driver',
          select: 'first_name last_name'
        }
          ,
          {
              path: '_site',
              select: 'name'
          }
      ],
        sortBy: {creation_dt: -1}

    };

    var query = {};

    if (tableState.pagination) {
      var pagination = tableState.pagination;
      number = pagination.number || $scope.itemsByPage;
      start = pagination.start / number + 1 || 1;
    }
    if (tableState.sort && tableState.sort.predicate) {
      options.sortBy = {};

      var predicate = tableState.sort.predicate;
      options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
    }



    if ($scope.search && $scope.search._created_by) {
      query._created_by = $scope.search._created_by;
    }
    if ($scope.search && $scope.search._company_owner) {
      query._company_owner = $scope.search._company_owner;
    }
    if ($scope.search && $scope.search._driver) {
      query._driver = $scope.search._driver;
    }
      if ($scope.search && $scope.search._site) {
          query._site = $scope.search._site;
      }

    SafeToLoadDriverSafetyVisitSrv.query({query: query, options: options}, number, start, function (response) {
      if (response.success) {
        $scope.visits = response.result;

                  // console.log("sheets listt :",$scope.visits)

        $scope.visitCount = response.total_count;
        tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
        tableState.pagination.totalItemCount = response.total_count;
        $scope.tableState = tableState;
      }
    });
  };

  $scope.remove = function (visit) {
        // console.log("sheets listt :")
    ngDialog.openConfirm({template: 'app/commons/dialogs/confirm.dlg.html', overlay: true, showClose: false, controller: ['$scope', function ($scope) {
            // controller logic
      $scope.title = $translate.instant("VISIT_DELETE_CONFIRM_TITLE");
      $scope.message = $translate.instant("VISIT_DELETE_CONFIRM_MSG", {driver: visit._driver ? visit._driver.first_name + " " + visit._driver.last_name : null});
    }]}).then(function (value) {
      SafeToLoadDriverSafetyVisitSrv.delete(visit._id, function (data) {
        if (data.success) {
          Notification($translate.instant("VISIT_DELETED", {driver: visit._driver ? visit._driver.first_name + " " + visit._driver.last_name : null}));
          $scope.loadVisits();
          $scope.tableState.pagination.start = 0;
        } else {
          console.log(data.error);
        }
      }, function (err) {

      });
    }, function (reason) {

    });
  };
});
