/**
 * Created by Hamdi on 18/12/2017.
 */
angular.module('app').controller('vehicleCtrlSheetShowCtrl', function ($scope,Excel,$timeout, $translate, SafeToLoadVehicleCtrlSheetSrv, $stateParams) {
  var options = {
    populate: [
      {
        path: '_company_owner',
        select: 'name'

      },
      {
        path: '_created_by',
        select: 'first_name last_name'
      },
      {
        path: '_asset',
        select: 'name model type useDate'
      }
        ,
        {
            path: '_site',
            select: 'name'
        }
    ]
  };

  var query = {};

  query._id = {$eq: $stateParams.sheetId};

   // SafeToLoadVehicleCtrlSheetSrv.getById($stateParams.sheetId, function (response) {
  SafeToLoadVehicleCtrlSheetSrv.query({query: query, options: options}, 1, 0, function (response) {
    if (response.success) {
      $scope.sheet = response.result[0];

          //  console.log($scope.sheet)
    }
  });

  $scope.printSheet = function (printSectionId) {
    var innerContents = document.getElementById(printSectionId).innerHTML;
    var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');

    if (popupWinindow) {
      popupWinindow.document.open();
      popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
      popupWinindow.document.close();
    } else {
      var innerContents = document.getElementById(printSectionId).innerHTML;
      var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
      popupWinindow.document.open();
      popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
      popupWinindow.document.close();
    }
  };

/*    $scope.exportToExcel = function (tableId) { // ex: '#my-table'
        var nom='ficheCtrlSheet';
        var exportHref = Excel.tableToExcel(tableId,nom);
        $timeout(function () {
            location.href = exportHref;
        }, 500); // trigger download
    };*/

    $scope.exportToExcel = function () {
        // ex: '#my-table'
        var nom='ficheCtrlSheet';
        var tableId='#controlSheetTable';

        $timeout(function () {

            $scope.exportHref = Excel.tableToExcel(tableId,nom);
        }, 100);

        $timeout(function () {
            //location.href = $scope.exportHref;
            var a = document.createElement('a');
            a.href = $scope.exportHref;
            a.download = nom+'-'+moment().format('YYYY-MM-DD-HHmmSS')+'.xls';
            a.click();
        }, 100);


    };
});
