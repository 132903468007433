/**
 * Created by Ahmed Alaya on 15/12/2017.
 */

angular.module('app').controller('sidebarFicheCtrl', function ($scope, $rootScope, $state) {
  $scope.listMenu = [];
  $scope.loadMenu = function () {
    var tab = [
      {
        name: "VEHICLE_CTRL_SHEET",
        permission: $rootScope.checkPermission('VEHICLE_CONTROL_SHEET', 'R'),
        route: 'app.fiche.vehicleCtrlSheet',
        icon: "fa fa-car"
      },
      {
        name: "DAILY_CHECK_LIST",
        permission: $rootScope.checkPermission('DAILY_CHECKLIST', 'R'),
        route: 'app.fiche.dailyCheckList',
        icon: 'fa fa-calendar-check-o'
      },
      {
        name: "DRIVER_SAFETY_VISIT",
        permission: $rootScope.checkPermission('DRIVER_SAFETY_VISIT', 'R'),
        route: 'app.fiche.driverSafetyVisit',
        icon: 'fa fa-user-circle'
      }

    ];
    angular.forEach(tab, function (item) {
      if (item.permission) {
        $scope.listMenu.push(item);
      }
    });
    if ($scope.listMenu && $scope.listMenu.length > 0) {
      $state.go($scope.listMenu[0].route);
    }
  };
  $scope.loadMenu();
});
