/**
 * Project: SafeToLoad
 * Created by  YOUSFI houcine 18/12/2017.
 */
angular.module('app').controller('dashDailyCtrl', function ($scope, $rootScope, $state, $timeout, $translate, constantSrv,ngDialog, SafeToLoadAssetSrv, SafeToLoadCheckListSrv) {
  $scope.dailyCheckProperties = constantSrv.dailyCheckProperties();
  $scope.notUsedProperties = $scope.dailyCheckProperties.notUsed;
  $scope.canUsedProperties = $scope.dailyCheckProperties.canUsed;

  var now = moment().clone();
  $scope.dayRes = now;

  $scope.pickerDay = {
    format: "DD/MM/YYYY",
    position: "top left",
    init: now,
    autoTrans: false,
    enableTime: false,
    enableDate: true,
    enableMinutes: false,
    defaultView: "DATE",
    getDate: function (date) {
      $scope.dayRes = date;
      $scope.loadAssetsList();
    }
  };

  $scope.loadAssetsList = function () {
    $scope.assetsIds = [];
    $scope.assetsData = {notUsed: [], canUsed: [], used: []};
      $scope.assetsMap = {};

    SafeToLoadAssetSrv.query({}, 1000, 0, function (response) {
      if (response.success) {
        $scope.assetsList = response.result;
        angular.forEach($scope.assetsList, function (asset) {
          $scope.assetsIds.push(asset._id);
            $scope.assetsMap[asset._id] = asset;

        });
        $scope.loadDailyCheckList();
      }
    });
  };
  $scope.loadDailyCheckList = function () {
    $scope.getQuery = function () {
      return {
        aggregate: [
          {
            $match: {

              $and: [
                {
                  _asset: {$in: $scope.assetsIds.map(function (elem) {
                    return "#OBJID#" + elem + "#OBJIDEND#";
                  })}
                },
                {
                  creation_dt: {
                    $gte: "#ISODATE#" + $scope.dayRes.clone().format('YYYY-MM-DD') + "T00:00:00.000Z#ISODATEEND#",
                    $lt: "#ISODATE#" + $scope.dayRes.clone().add(1, 'days').format('YYYY-MM-DD') + "T00:00:00.000Z#ISODATEEND#"

                  }
                }
              ]

            }

          },
                    {$sort: {creation_dt: -1}},
          {
            $project: {
              _asset: 1,
              document: "$$ROOT"
            }
          },
          {
            $group: {
              _id: '$_asset',

              data: {
                $push: {
                  properties: "$document"
                }
              }
            }
          },
          {
            $project: {
              _id: '$_id',
              data: {
                $arrayElemAt: ["$data", 0]
              }
            }
          }
        ]
      };
    };

    SafeToLoadCheckListSrv.agg($scope.getQuery(), 10000, 0, function (response) {
      if (response.success) {
        $scope.dailyCheckList = response.result;
        if ($scope.dailyCheckList.length > 0) {
          $scope.getDailyControlChart();
        }
      }
    });
  };

  $scope.getDailyControlChart = function () {
    $scope.dailyCheckList.map(function (elem) {
        var assetElem = $scope.assetsMap[elem._id]

        var unUsedProprties = $scope.filterProperties($scope.notUsedProperties, elem.data.properties);
      var canUsedProprties = $scope.filterProperties($scope.canUsedProperties, elem.data.properties);
      var unUsed = false;
      var canUsed = false;
      for (var un in unUsedProprties) {
        if (unUsedProprties[un]) {
          $scope.assetsData.notUsed.push(assetElem);
          unUsed = true;
          return;
        }
      }
      if (!unUsed) {
        for (var can in canUsedProprties) {
          if (canUsedProprties[can]) {
            $scope.assetsData.canUsed.push(assetElem);
            canUsed = true;
            return;
          }
        }
      }
      if (!canUsed && !unUsed) {
        $scope.assetsData.used.push(assetElem);
      }
    });
    $scope.loadDailyChart();
  };
    $scope.getAssetsDetails = function(assets) {
        if(assets && assets.length>0) {
            ngDialog.open({
                template: 'app/commons/dialogs/assets.info.dlg.html',
                controller: 'assetsInfoCtrl',
                resolve: {
                    assets: function () {
                        return assets;
                    }
                }
            })
        }
    }
  $scope.filterProperties = function (accepted, allProperties) {
    var result = {};
    for (var type in allProperties) {
      if (accepted.indexOf(type) > -1) {
        result[type] = allProperties[type];
      }
    }
    return result;
  };

  $scope.loadDailyChart = function () {
    $scope.dailyData = [];
    $scope.dailyData.push([$translate.instant("NOT_USED_ASSETS"), $scope.assetsData.notUsed.length]);
    $scope.dailyData.push([$translate.instant("CAN_USED_ASSETS"), $scope.assetsData.canUsed.length]);
    $scope.dailyData.push([$translate.instant("USED_ASSETS"), $scope.assetsData.used.length]);
    $scope.chartDailyLoad();
  };
  $scope.chartDailyLoad = function () {
    $timeout(function () {
      Highcharts.chart("pieDailyControl", $scope.dailyChartConfig).reflow();
    });
    $scope.dailyChartConfig = {
      options: {
        chart: {
          type: 'pie',

          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false
        }
      },
      plotOptions: {
        pie: {
          colors: [
            '#ED561B',
            '#FFA500',
            '#50B432'
          ],
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          }
        }
      },

      series: [{
        type: 'pie',
        name: $translate.instant("NUMBER"),
        data: $scope.dailyData,
        color: '#0079e3',
        dataLabels: {
          enabled: true,
          color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || '#0067c1'

        }
      }],

      title: {
        text: $translate.instant("ASSETS_STATUS")
      },
      subtitle: {
        text: ""
      }

    };
  };

        // $scope.loadAssetsList();
});
