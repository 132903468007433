angular.module('app').controller('adminRoleAddCtrl', function ($scope, $rootScope, $state, $translate, SafeToLoadRoleSrv, ngDialog, Notification) {
  $scope.role = {};
  $scope.toDo = "PERMISSION";
  $scope.accessTypes = [
        {code: null, label: "SELECT_ACCESS_TYPE"},
        {code: "X", label: "NO_LIMIT_ACCESS"},
        {code: "A", label: "ATTACHED_ACCESS"},
        {code: "O", label: "OWNER_ACCESS"},
        {code: "U", label: "USER_ACCESS"}

  ];
  SafeToLoadRoleSrv.query({}, 10000, 1, function (response) {
    if (response.success) {
      $scope.roles = response.result;
    }
  });
  SafeToLoadRoleSrv.getById($scope.SESSION.user._role._id, function (res) {
    if (res.success) {
      $scope.userRole = res.result;
    } else {
      console.log(res.error);
    }
    $scope.userRole.permissions = $scope.userRole.permissions.map(function (perm) {
      res = perm.substring(perm.indexOf("_") + 1, perm.length);
      var modelName = res.substring(res.indexOf("_") + 1, res.length);
      return {
        model: modelName,
        r: false,
        c: false,
        u: false,
        d: false,
        accessType: null
      };
    });
  }, function (err) {

  });
    /**
     * Save role
     */
  $scope.save = function () {
    var permissions = [];
    if ($scope.userRole) {
      permissions = $scope.userRole.permissions.map(function (perm) {
        var permString = (perm.c && 'C' || '') + (perm.r && 'R' || '') + (perm.u && 'U' || '') + (perm.d && 'D' || '') + "_" + (perm.accessType || 'U') + "_" + perm.model;
                // if(!perm.c && !perm.r && !perm.u && !perm.d ){
                //    console.log("here")
                // }
                // else{
        return permString;
                // }
      });
    }
    $scope.role.permissions = permissions;
    SafeToLoadRoleSrv.add($scope.role, function (data) {
      if (data.success) {
        $state.go('app.admin.roles');
        Notification.success($translate.instant("ALERT_ROLE_ADDED", {name: $scope.role._id}));
        $scope.loadRoles();
      } else {
        console.log(data.error);
      }
    });
  };
  $scope.permForm = function () {
    $scope.toDo = "PERMISSION";
  };
  $scope.childForm = function () {
    $scope.roles = $scope.roles.map(function (element) {
      return element._id;
    });
    $scope.toDo = "CHILDS";
  };

    /**
     * Cancel editing mode
     */
  $scope.cancel = function () {
    $scope.role = {};
    $state.go('app.admin.roles');
  };
});
