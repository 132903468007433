angular
    .module('app')
    .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise("/login");

  $stateProvider
        .state('login', {
          url: '/login',
          templateUrl: 'app/pages/login/login.tpl.html',
          controller: 'loginCtrl'
        })
        .state('app', {
          url: '/app',
          templateUrl: 'app/pages/main.tpl.html',
          controller: 'mainCtrl',
          abstract: true
        })
        .state('app.home', {
          url: '/home',
          templateUrl: "app/pages/home/home.tpl.html",
          controller: "homeCtrl"
        })
        .state('app.fiche', {
          url: '/fiche',
          views: {
            sidebar: {
              templateUrl: "app/pages/home/fiche/sidebar/fiche.sidebar.tpl.html",
              controller: "sidebarFicheCtrl"
            },
            content: {
              templateUrl: "app/pages/home/fiche/fiche.tpl.html",
              controller: "ficheCtrl"
            }
          }
        })
        .state('app.profile', {
          url: '/profile',
          views: {

            content: {
              templateUrl: 'app/pages/home/profile/profile.tpl.html',
              controller: "profileCtrl"
            }
          }
        })
        .state('app.admin', {
          url: '/admin',
          views: {
            sidebar: {
              templateUrl: "app/pages/admin/sidebar/admin.sidebar.tpl.html",
              controller: "adminSidebarCtrl"
            },
            content: {
              templateUrl: "app/pages/admin/admin.tpl.html",
              controller: "adminCtrl"
            }
          }

        })
        .state('app.dashboard', {
          url: '/dashboard',
          views: {
            sidebar: {
              templateUrl: "app/pages/home/dashboard/sidebar/dashboard.sidebar.tpl.html",
              controller: "dashboardSidebarCtrl"
            },
            content: {
              templateUrl: "app/pages/home/dashboard/dashboard.tpl.html",
              controller: "dashboardCtrl"
            }
          }

        })
      .state('app.statistic', {
          url: '/statistic',
          views: {
              sidebar: {
                  templateUrl: "app/pages/home/statistic/sidebar/statistic.sidebar.tpl.html",
                  controller: "statisticSidebarCtrl"
              },
              content: {
                  templateUrl: "app/pages/home/statistic/statistic.tpl.html",
                  controller: "statisticCtrl"
              }
          }

      })
        .state('app.admin.users', {
          url: '/users',
          templateUrl: 'app/pages/admin/users/users.tpl.html',
          controller: "adminUserCtrl"
        })
        .state('app.admin.users.add', {
          url: '/add',
          templateUrl: 'app/pages/admin/users/user.add.tpl.html',
          controller: "adminUserAddCtrl"
        })

        .state('app.admin.users.edit', {
          url: '/edit/:userId',
          templateUrl: 'app/pages/admin/users/user.edit.tpl.html',
          controller: "adminUserEditCtrl"
        })
        .state('app.admin.assets', {
          url: '/assets',
          templateUrl: 'app/pages/admin/assets/assets.tpl.html',
          controller: "adminAssetCtrl"
        })
        .state('app.admin.assets.add', {
          url: '/add',
          templateUrl: 'app/pages/admin/assets/asset.add.tpl.html',
          controller: "adminAssetAddCtrl"
        })
        .state('app.admin.assets.edit', {
          url: '/edit/:assetId',
          templateUrl: 'app/pages/admin/assets/asset.edit.tpl.html',
          controller: "adminAssetEditCtrl"
        })
        .state('app.admin.drivers', {
          url: '/drivers',
          templateUrl: 'app/pages/admin/drivers/drivers.tpl.html',
          controller: "adminDriverCtrl"
        })
        .state('app.admin.drivers.add', {
          url: '/add',
          templateUrl: 'app/pages/admin/drivers/driver.add.tpl.html',
          controller: "adminDriverAddCtrl"
        })
        .state('app.admin.drivers.edit', {
          url: '/edit/:driverId',
          templateUrl: 'app/pages/admin/drivers/driver.edit.tpl.html',
          controller: "adminDriverEditCtrl"
        })
        .state('app.admin.roles', {
          url: '/roles',
          templateUrl: 'app/pages/admin/roles/roles.tpl.html',
          controller: "adminRoleCtrl"
        })
        .state('app.admin.roles.add', {
          url: '/add',
          templateUrl: 'app/pages/admin/roles/role.add.tpl.html',
          controller: "adminRoleAddCtrl"
        })
        .state('app.admin.roles.edit', {
          url: '/edit/:roleId',
          templateUrl: 'app/pages/admin/roles/role.edit.tpl.html',
          controller: "adminRoleEditCtrl"
        })
        .state('app.admin.sites', {
          url: '/sites',
          templateUrl: 'app/pages/admin/sites/sites.tpl.html',
          controller: "adminSiteCtrl"
        })
        .state('app.admin.sites.add', {
          url: '/add',
          templateUrl: 'app/pages/admin/sites/site.add.tpl.html',
          controller: "adminSiteAddCtrl"
        })
        .state('app.admin.sites.edit', {
          url: '/edit/:siteId',
          templateUrl: 'app/pages/admin/sites/site.edit.tpl.html',
          controller: "adminSiteEditCtrl"
        })
      .state('app.admin.subconstractors', {
          url: '/subconstractors',
          templateUrl: 'app/pages/admin/subconstractors/subconstractors.tpl.html',
          controller: "adminSubConstractorCtrl"
      })
      .state('app.admin.subconstractors.add', {
          url: '/add',
          templateUrl: 'app/pages/admin/subconstractors/subconstractor.add.tpl.html',
          controller: "adminSubConstractorAddCtrl"
      })
      .state('app.admin.subconstractors.edit', {
          url: '/edit/:subconstractorId',
          templateUrl: 'app/pages/admin/subconstractors/subconstractor.edit.tpl.html',
          controller: "adminSubConstractorEditCtrl"
      })
      .state('app.admin.companies', {
          url: '/companies',
          templateUrl: 'app/pages/admin/companies/company.tpl.html',
          controller: "adminCompanyCtrl"
        })
        .state('app.admin.companies.add', {
          url: '/add',
          templateUrl: 'app/pages/admin/companies/company.add.tpl.html',
          controller: "adminCompanyAddCtrl"
        })
        .state('app.admin.companies.edit', {
          url: '/edit/:companyId',
          templateUrl: 'app/pages/admin/companies/company.edit.tpl.html',
          controller: "adminCompanyEditCtrl"
        })

       .state('app.fiche.dailyCheckList', {
         url: '/dailyCheckList',
         templateUrl: 'app/pages/home/fiche/checkList/checkList.tpl.html',
         controller: "checkListCtrl"
       })
        .state('app.fiche.dailyCheckList.show', {
          url: '/show/:listId',
          templateUrl: 'app/pages/home/fiche/checkList/checkList.show.tpl.html',
          controller: "checkListShowCtrl"
        })
        .state('app.fiche.vehicleCtrlSheet', {
          url: '/vehicleCtrlSheet',
          templateUrl: 'app/pages/home/fiche/vehicleCtrlSheet/vehicleCtrlSheet.tpl.html',
          controller: "vehicleCtrlSheetCtrl"
        })
        .state('app.fiche.vehicleCtrlSheet.show', {
          url: '/show/:sheetId',
          templateUrl: 'app/pages/home/fiche/vehicleCtrlSheet/vehicleCtrlSheet.show.tpl.html',
          controller: "vehicleCtrlSheetShowCtrl"
        })
        .state('app.fiche.driverSafetyVisit', {
          url: '/driverSafetyVisit',
          templateUrl: 'app/pages/home/fiche/driverSafetyVisit/driverSafetyVisit.tpl.html',
          controller: "driverSafetyVisitCtrl"
        })
        .state('app.fiche.driverSafetyVisit.show', {
          url: '/show/:visitId',
          templateUrl: 'app/pages/home/fiche/driverSafetyVisit/driverSafetyVisit.show.tpl.html',
          controller: "driverSafetyVisitShowCtrl"
        })
        .state('app.dashboard.control', {
          url: '/control',
          templateUrl: 'app/pages/home/dashboard/control/control.tpl.html',
          controller: "dashControlCtrl"
        })
        .state('app.dashboard.daily', {
          url: '/daily',
          templateUrl: 'app/pages/home/dashboard/daily/daily.tpl.html',
          controller: "dashDailyCtrl"
        })
        .state('app.dashboard.drivers', {
          url: '/drivers',
          templateUrl: 'app/pages/home/dashboard/drivers/drivers.tpl.html',
          controller: "dashDriverCtrl"
        })
      .state('app.statistic.control', {
          url: '/control',
          templateUrl: 'app/pages/home/statistic/control/control.tpl.html',
          controller: "statControlCtrl"
      })
      .state('app.statistic.daily', {
          url: '/daily',
          templateUrl: 'app/pages/home/statistic/daily/daily.tpl.html',
          controller: "statDailyCtrl"
      })
      .state('app.statistic.drivers', {
          url: '/drivers',
          templateUrl: 'app/pages/home/statistic/drivers/drivers.tpl.html',
          controller: "statDriverCtrl"
      });
}
