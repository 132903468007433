angular.module('app').controller('adminSidebarCtrl', function ($scope, $rootScope, $state) {
  $scope.listMenu = [];
  $scope.loadMenu = function () {
    var tab = [
      {
        name: "USERS",
        permission: $rootScope.checkPermission('USER', 'R'),
        route: 'app.admin.users',
        icon: "fa fa-user"
      },
        {
        name: "COMPANIES",
        permission: $rootScope.checkPermission('COMPANY', 'R'),
        route: 'app.admin.companies',
        icon: "fa fa-building"
      },
      {
        name: "ASSETS",
        permission: $rootScope.checkPermission('ASSET', 'R'),
        route: 'app.admin.assets',
        icon: 'fa fa-car'
      },
      {
        name: "DRIVERS",
        permission: $rootScope.checkPermission('DRIVER', 'R'),
        route: 'app.admin.drivers',
        icon: 'fa fa-group'
      },
        {
        name: "SITES",
        permission: $rootScope.checkPermission('SITE', 'R'),
        route: 'app.admin.sites',
        icon: 'fa fa-map-marker'
      },
        {
            name: "SUB_CONSTRACTORS",
            permission: $rootScope.checkPermission('SUB_CONSTRACTOR', 'R'),
            route: 'app.admin.subconstractors',
            icon: 'fa fa-map-marker'
        },
      {
        name: "ROLES",
        permission: $rootScope.checkPermission('ROLE', 'R', 'X'),
        route: 'app.admin.roles',
        icon: 'fa fa-user-circle'
      }

    ];
    angular.forEach(tab, function (item) {
      if (item.permission) {
        $scope.listMenu.push(item);
      }
    });
    if ($scope.listMenu && $scope.listMenu.length > 0) {
      $state.go($scope.listMenu[0].route);
    }
  };
  $scope.loadMenu();
});
