angular.module('app').controller('adminRoleEditCtrl', function ($scope, $rootScope, $stateParams, $state, $translate, SafeToLoadRoleSrv, ngDialog, Notification) {
  $scope.role = {};
  $scope.toDo = "PERMISSION";
  $scope.accessTypes = [
        {code: null, label: "SELECT_ACCESS_TYPE"},
        {code: "X", label: "NO_LIMIT_ACCESS"},
        {code: "A", label: "ATTACHED_ACCESS"},
        {code: "O", label: "OWNER_ACCESS"},
        {code: "U", label: "USER_ACCESS"}

  ];
  SafeToLoadRoleSrv.query({}, 10000, 1, function (response) {
    if (response.success) {
      $scope.roles = response.result;
    }
  });
  $scope.sessionRolePermissions = $scope.SESSION.user._role.permissions;
  SafeToLoadRoleSrv.getById($stateParams.roleId, function (data) {
    if (data.success && data.result) {
      $scope.roles = $scope.roles.map(function (element) {
        return element._id;
      });
      $scope.role = data.result;
      $scope.sessionRolePermissions = $scope.sessionRolePermissions.map(function (allPerm) {
        var allCrud = allPerm.substring(0, allPerm.indexOf("_"));
        var allRes = allPerm.substring(allPerm.indexOf("_") + 1, allPerm.length);
        var allAccessType = allRes.substring(0, allRes.indexOf("_"));
        allRes = allPerm.substring(allPerm.indexOf("_") + 1, allPerm.length);
        var allModelName = allRes.substring(allRes.indexOf("_") + 1, allRes.length);
        return {
          allModel: allModelName,
          r: (allCrud.indexOf("R") >= 0),
          c: (allCrud.indexOf("C") >= 0),
          u: (allCrud.indexOf("U") >= 0),
          d: (allCrud.indexOf("D") >= 0),
          accessType: allAccessType
        };
      });
      $scope.role.permissions = $scope.role.permissions.map(function (perm) {
        var crud = perm.substring(0, perm.indexOf("_"));
        var res = perm.substring(perm.indexOf("_") + 1, perm.length);
        var accessType = res.substring(0, res.indexOf("_"));
        res = perm.substring(perm.indexOf("_") + 1, perm.length);
        var modelName = res.substring(res.indexOf("_") + 1, res.length);
        return {
          model: modelName,
          r: (crud.indexOf("R") >= 0),
          c: (crud.indexOf("C") >= 0),
          u: (crud.indexOf("U") >= 0),
          d: (crud.indexOf("D") >= 0),
          accessType: accessType
        };
      });
      if ($scope.sessionRolePermissions.length != $scope.role.permissions.length) {
        for (var i = 0; i < $scope.sessionRolePermissions.length; i++) {
          var sessionModel = $scope.sessionRolePermissions[i].allModel;
          for (var j = 0; j < $scope.role.permissions.length; j++) {
            var currentModel = $scope.role.permissions[j].model;
            if (sessionModel == currentModel) {
                            // i++;
              break;
            } else if (j == $scope.role.permissions.length - 1 && (sessionModel != currentModel)) {
              $scope.role.permissions.push(
                {model: sessionModel,
                  r: false,
                  c: false,
                  u: false,
                  d: false,
                  accessType: null
                });
            }
          }
        }
      }

      if ($scope.role.description) {
        $scope.config = JSON.stringify($scope.role.description);
      }
    } else {
      console.log(data.error);
    }
  }, function (error) {
    if (error) {
      console.log(error);
      $state.go("app.admin.roles");
    }
  });
    /**
     * Save role
     */
  $scope.save = function () {
    var permissions = [];
    if ($scope.role) {
      permissions = $scope.role.permissions.map(function (perm) {
        var permString = (perm.c && 'C' || '') + (perm.r && 'R' || '') + (perm.u && 'U' || '') + (perm.d && 'D' || '') + "_" + (perm.accessType || 'U') + "_" + perm.model;
        return permString;
      });
    }
    $scope.role.permissions = permissions;
    SafeToLoadRoleSrv.updateById($scope.role._id, $scope.role, function (data) {
      if (data.success) {
        $state.go('app.admin.roles');
        Notification.success($translate.instant("ALERT_ROLE_UPDATED", {name: $scope.role._id}));
        $scope.loadRoles();
      } else {
        $state.go('app.admin.roles');
        Notification.error('ID error');
        console.log(data.error);
      }
    });
  };

  $scope.permForm = function () {
    $scope.toDo = "PERMISSION";
  };
  $scope.childForm = function () {
    $scope.toDo = "CHILDS";
  };
    /**
     * Cancel editing mode
     */
  $scope.cancel = function () {
    $scope.role = {};
    $state.go('app.admin.roles');
  };
});
