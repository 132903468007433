/**
 * Created by Hamdi on 21/12/2017.
 */
angular.module('app').controller('checkListShowCtrl', function ($scope, $rootScope,Excel,$timeout, $state, $translate, $stateParams,
                                                            SafeToLoadCheckListSrv) {

    $scope.exportMode=false;

    var options = {
        populate: [
            {
                path: '_company_owner',
                select: 'name'

            },
            {
                path: '_created_by',
                select: 'first_name last_name'
            },
            {
                path: '_asset',
                select: 'name type'
            }
            ,
            {
                path: '_site',
                select: 'name'
            }
        ]
    };

    var query = {};

    query._id = {$eq: $stateParams.listId};

    // SafeToLoadVehicleCtrlSheetSrv.getById($stateParams.sheetId, function (response) {
    SafeToLoadCheckListSrv.query({query: query, options: options}, 1, 0, function (response) {
        if (response.success) {
            $scope.list = response.result[0];

            // console.log($scope.list)
        }
    });

    $scope.printList = function (printSectionId) {
        var innerContents = document.getElementById(printSectionId).innerHTML;
        var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');

        if (popupWinindow) {
            popupWinindow.document.open();
            popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
            popupWinindow.document.close();
        } else {
            var innerContents = document.getElementById(printSectionId).innerHTML;
            var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
            popupWinindow.document.open();
            popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
            popupWinindow.document.close();
        }
    };


    $scope.exportToExcel = function () {
        // ex: '#my-table'
        $scope.exportMode=true;
        var nom='cheklist';
        var tableId='#checklistTable';

        $timeout(function () {

            $scope.exportHref = Excel.tableToExcel(tableId,nom);
        }, 100);

        $timeout(function () {
            //location.href = $scope.exportHref;
            var a = document.createElement('a');
            a.href = $scope.exportHref;
            a.download = nom+'-'+moment().format('YYYY-MM-DD-HHmmSS')+'.xls';
            a.click();
        }, 100);

        $timeout(function () {
            $scope.exportMode=false;
            },150); // trigger download

    };

    /*$scope.exportData = function (tableId) {

        var blob = new Blob([document.getElementById(tableId).innerHTML], {
       // var blob = new Blob([$(tableId)], {
            //type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
            type: 'text/plain;charset=utf-8'
        });
       saveAs(blob, "Leads "+new Date()+".xlsx");
    };*/



});