/**
 * Project: SafeToLoad
 * Created by  YOUSFI houcine 15/12/2017.
 */

angular.module("app").controller('loginCtrl', function ($rootScope, $scope, $window, $state, loginSrv, ngDialog, $cookieStore, $translate) {
  $scope.user = {};
  $scope.rememberMe = $cookieStore.get('rememberme');
  $scope.user.login = $cookieStore.get('login');
  $scope.user.password = $cookieStore.get('password');
  if ($cookieStore.get('lang') === 'fr') {
    $scope.language = "fr";
  } else if ($cookieStore.get('lang') === 'en') {
    $scope.language = "en";
  } else {
    $scope.language = "fr";
  }

  $scope.changeLanguage = function (langKey) {
    if (langKey === "en") {
      $scope.language = "en";
      $cookieStore.put('lang', 'en');
      $translate.use($scope.language);
    } else if (langKey === "fr") {
      $scope.language = "fr";
      $cookieStore.put('lang', 'fr');
      $translate.use($scope.language);
    }
  };
  $scope.signIn = function () {
    var signedIn = angular.isDefined($rootScope.SESSION);

    if (signedIn) {
      loginSrv.logout(function () {
        $rootScope.appData = {};
        $rootScope.maplayers = {};
        console.debug("logout success");
      });
    }

    if ($scope.rememberMe) {
      $cookieStore.put('login', $scope.user.login);
      $cookieStore.put('password', $scope.user.password);
      $cookieStore.put('lang', $scope.language);
    } else {
      $cookieStore.remove('rememberme');
      $cookieStore.remove('login');
      $cookieStore.remove('password');
      $cookieStore.remove('lang');
    }
    loginSrv.login($scope.user, function () {
            // console.log("login success");
      $state.go("app.home");
    }, function (data) {
      ngDialog.openConfirm({template: 'app/commons/dialogs/error.dlg.html',
        overlay: true,
        showClose: false,
        controller: ['$scope', function ($scope) {
                    // controller logic
          $scope.title = $translate.instant("LOGIN_ERROR");
          if (data.error) {
            if (data.error.errorCode) {
              $scope.message = $translate.instant(data.error.errorCode);
            } else {
              $scope.message = data.error;
            }
          } else {
            $scope.message = "Unknown ERROR";
          }
        }]});
    });
  };

  $scope.remember = function () {
    if ($scope.rememberMe) {
      $cookieStore.put('rememberme', true);
      $cookieStore.put('login', $scope.user.login);
      $cookieStore.put('password', $scope.user.password);
    } else {
      $cookieStore.remove('rememberme');
      $cookieStore.remove('login');
      $cookieStore.remove('password');
    }
  };
    /* Open dialog window for reset password*/
  $scope.recoverPassword = function () {
    ngDialog.open({
      template: 'app/login/forget.password.tpl.html',
      controller: 'forgetPasswordCtrl',
      scope: $scope
    });
  };
  $rootScope.$on("sessionExpired", function () {
    Notification.error({message: "Session expirée", replaceMessage: true});
    $state.go('login');
  });
});
