/**
 * Project: safeToLoad
 * Created by Houcine Yousfi on 02/05/2018.
 */
angular.module("app").controller('assetsInfoCtrl', function ($scope, assets) {
        $scope.assets = assets;


});


