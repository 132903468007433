angular.module('app').controller('adminDriverEditCtrl', function ($scope,$rootScope,$stateParams, $state, $translate, SafeToLoadDriverSrv, ngDialog, Notification) {
    $scope.driver = {};
    var query = {
        query: {
            _id: $stateParams.driverId
        },
        options: {
            populate: [
                {
                    path: '_company_owner',
                    select: 'name'
                },
                {
                    path: '_ctry',
                    select: 'name'
                },
                {
                    path: '_site',
                    select: 'name'
                }  ,
                {
                    path: '_sub_constractor',
                    select: 'name'
                }

            ]

        }
    };
    SafeToLoadDriverSrv.query(query,1,1, function (response) {
        $scope.driver = response.result[0];
    }, function (err) {
        console.log(err);
    });

    /**
     * Save driver
     */
    $scope.save = function () {

        SafeToLoadDriverSrv.updateById($scope.driver._id, $scope.driver, function (response) {
            if(response.success){
                Notification.success({
                    title : $translate.instant("UPDATE"),
                    message : $translate.instant("ALERT_DRIVER_UPDATED",{first_name:$scope.driver.first_name})
                });
                $state.go('app.admin.drivers');
                $scope.driver  = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.driver.last_name +"!"
                });
                $scope.driver  = {};
            }
        }, function (err) {
            var msg = "";
            if( err.error.errorString){
                msg = err.error.errorString;
            }
            Notification.error({
                title: "Erreur",
                message : msg
            });
        })
    };


    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.driver = {};
        $state.go('app.admin.drivers');
    }

});