angular.module('app').controller('adminDriverAddCtrl', function ($scope,$rootScope, $state, $translate, SafeToLoadDriverSrv, ngDialog, Notification) {
    $scope.driver = {};
    /**
     * Save driver
     */
    $scope.save = function () {
        $scope.driver._ctry = $scope.driver._ctry.code
        SafeToLoadDriverSrv.add($scope.driver, function (response) {
            if(response.success){
                Notification.success({
                    title : $translate.instant("ADD"),
                    message : $translate.instant("ALERT_DRIVER_ADDED",{first_name:$scope.driver.first_name})
                });
                $state.go('app.admin.drivers');
                $scope.driver  = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.driver.last_name +"!"
                });
                $scope.driver  = {};
            }
        }, function (err) {
            var msg = "";
            if( err.error.errorString){
                msg = err.error.errorString;
            }
            Notification.error({
                title: "Erreur",
                message : msg
            });
        })
    };


    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.driver = {};
        $state.go('app.admin.drivers');
    }

});