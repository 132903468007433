angular.module('app').controller('adminSubConstractorCtrl', function ($scope, $rootScope, $state, $translate, SafeToLoadSubConstractorSrv, ngDialog, Notification) {
    $scope.subconstractor = {};
    $scope.itemsByPage = 5;
    $scope.search = {};

    $scope.loadSubConstractors = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();

            return;
        }
        var start = 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
        var number = $scope.itemsByPage;
        var query = {
            options: { populate: [
                    {
                        path: '_company_owner',
                        select: 'name'
                    }
                    ]
            }
        };
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        if (tableState.sort && tableState.sort.predicate) {
            query.options.sortBy = {};

            var predicate = tableState.sort.predicate;
            query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }
        var requery = {};
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.name) {
                requery.name = {$regex: tableState.search.predicateObject.name, $options: "i"};
            }
        }
        if ($scope.search) {
            if ($scope.search._company_owner) {
                requery._company_owner = $scope.search._company_owner;
            }
        }
        query.query = requery;
        SafeToLoadSubConstractorSrv.query(query, number, start, function (data) {
            if (data.success) {
                $scope.subconstractors = data.result;
                $scope.subconstractorCount = data.total_count;
                tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                tableState.pagination.totalItemCount = data.total_count;
                $scope.tableState = tableState;
            } else {
                console.error(data.error);
            }
        }, function (data) {
            console.log(data);
        });
    };
    /* delete subconstractor*/
    $scope.remove = function (subConstractor) {
        ngDialog.openConfirm({template: 'app/commons/dialogs/confirm.dlg.html', overlay: true, showClose: false, controller: ['$scope', function ($scope) {
                // controller logic
                $scope.title = $translate.instant("SUB_CONSTRACTOR_DELETE_CONFIRM_TITLE", {name: subconstractor.name});
                $scope.message = $translate.instant("SUB_CONSTRACTOR_DELETE_CONFIRM_MESSAGE", {name: subconstractor.name});
            }]}).then(function () {
            SafeToLoadSubConstractorSrv.delete(subconstractor._id, function (data) {
                if (data.success) {
                    Notification($translate.instant("ALERT_SUB_CONSTRACTOR_DELETED", {name: subconstractor.name}));
                    $scope.loadSubConstractors();
                    $scope.tableState.pagination.start = 0;
                } else {
                    console.log(data.error);
                }
            }, function (err) {

            });
        }, function (reason) {

        });
    };
});
