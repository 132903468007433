/**
 * Project: SafeToLoad
 * Created by  YOUSFI houcine 18/12/2017.
 */
angular.module('app').controller('dashControlCtrl', function ($scope, $rootScope, $state,ngDialog, SafeToLoadAssetSrv, SafeToLoadVehicleCtrlSheetSrv, constantSrv, $timeout, $translate) {
    $scope.assetControlProperties = constantSrv.assetControlProperties();
    $scope.assetRequiredControlProperties = constantSrv.assetRequiredControlProperties();

  $scope.loadAssetsList = function () {
    $scope.assetsIds = [];
      $scope.assetsMap = {};

    SafeToLoadAssetSrv.query({}, 1000, 0, function (response) {
      if (response.success) {
        $scope.assetsList = response.result;
        angular.forEach($scope.assetsList, function (asset) {
          $scope.assetsIds.push(asset._id);
            $scope.assetsMap[asset._id] = asset;

        });
        $scope.loadAssetControlSheetList();
      }
    });
  };
  $scope.loadAssetControlSheetList = function () {
    $scope.getQuery = function () {
      return {
        aggregate: [
          {
            $match: {
              _asset: {
                $in: $scope.assetsIds.map(function (elem) {
                  return "#OBJID#" + elem + "#OBJIDEND#";
                })
              }
            }
          },

                    {$sort: {creation_dt: -1}},
          {
            $project: {
              _asset: 1,
              document: "$$ROOT"
            }
          },
          {
            $group: {
              _id: '$_asset',

              data: {
                $push: {
                  properties: "$document"
                }
              }
            }
          },
          {
            $project: {
              _id: '$_id',
              data: {
                $arrayElemAt: ["$data", 0]
              }
            }
          }

        ]
      };
    };

    SafeToLoadVehicleCtrlSheetSrv.agg($scope.getQuery(), 10000, 0, function (response) {
      if (response.success) {
        $scope.assetControlSheetList = response.result;
        $scope.getAssetsControlScores();
      }
    });
  };

  $scope.getAssetsControlScores = function () {
    $scope.totalAssetControl = $scope.assetControlSheetList.length;
      $scope.controlData = {};
      $scope.controlAssets = {};
      $scope.unUsedAssets = [];
      $scope.canUsedAssets = [];
    $scope.scoreData = {};
    $scope.assetControlSheetList.map(function (elem) {
        var assetElem = $scope.assetsMap[elem._id]

        for (var prop in elem.data.properties) {
        if ($scope.assetControlProperties.indexOf(prop) != -1) {
          if ($scope.controlAssets.hasOwnProperty(prop)) {
            if (elem.data.properties[prop]) {
                $scope.controlAssets[prop].push(assetElem)
                if ($scope.assetRequiredControlProperties.indexOf(prop) != -1) {
                    if($scope.unUsedAssets.indexOf(assetElem)==-1){
                        $scope.unUsedAssets.push(assetElem)
                    }
                }
            }
          } else if (elem.data.properties[prop]) {
              $scope.controlAssets[prop] = [assetElem]
              if ($scope.assetRequiredControlProperties.indexOf(prop) != -1) {
              if($scope.unUsedAssets.indexOf(assetElem)==-1){
                  $scope.unUsedAssets.push(assetElem)
              }
              }
          }
        }
      }
    });

    for (var prpty in $scope.assetControlProperties) {
      var key = $scope.assetControlProperties[prpty];
        if ($scope.controlAssets.hasOwnProperty(key)) {
            $scope.scoreData[key] = (($scope.controlAssets[key].length * 100) / $scope.totalAssetControl).toFixed(1);
        } else {
            $scope.scoreData[key] = 0;
        }
    }


      angular.forEach($scope.assetControlSheetList, function (ctrl) {
          var asset =$scope.assetsMap[ctrl._id]
          if($scope.unUsedAssets .indexOf(asset)==-1){
              $scope.canUsedAssets.push(asset)
          }

      });

  };

    $scope.getAssetsDetails = function(assets) {
        if(assets && assets.length>0) {
            ngDialog.open({
                template: 'app/commons/dialogs/assets.info.dlg.html',
                controller: 'assetsInfoCtrl',
                resolve: {
                    assets: function () {
                        return assets;
                    }
                }
            })
        }
    }
  $scope.loadAssetsList();
});
