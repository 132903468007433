/**
 * Project: safeToLoad
 * Created by Houcine Yousfi on 02/05/2018.
 */

angular.module("app").controller('driversInfoCtrl', function ($scope, drivers) {

        $scope.drivers = drivers;




});


