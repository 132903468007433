/**
 * Created by Hamdi on 20/12/2017.
 */
angular.module('app').controller('driverSafetyVisitShowCtrl', function ($scope,Excel,$timeout,$stateParams, $translate, Notification,
                                                                        SafeToLoadDriverSafetyVisitSrv) {
/*    $scope.myObj = {
        "color": "white",
        "background-color": "coral",
        "font-size": "60px",
        "padding": "50px"
    }*/

  var options = {
    populate: [
      {
        path: '_company_owner',
        select: 'name'

      },
      {
        path: '_created_by',
        select: 'first_name last_name'
      },
      {
        path: '_driver',
        select: 'first_name last_name'
      }
        ,
        {
            path: '_site',
            select: 'name'
        }
    ]
  };

  var query = {};

  query._id = {$eq: $stateParams.visitId};

    // SafeToLoadVehicleCtrlSheetSrv.getById($stateParams.sheetId, function (response) {
  SafeToLoadDriverSafetyVisitSrv.query({query: query, options: options}, 1, 0, function (response) {
    if (response.success) {
      $scope.visit = response.result[0];
      $scope.count = 0;
      $scope.countGreen = 0;
      $scope.countOrange = 0;
      $scope.countRed = 0;
          //  console.log($scope.visit)
      if ($scope.visit) {
        if ($scope.visit.verifBeforeStart && $scope.visit.verifBeforeStart == 2) {
          $scope.greenVerifBeforeStart = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.verifBeforeStart && $scope.visit.verifBeforeStart == -4) {
          $scope.orangeVerifBeforeStart = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.verifBeforeStart && $scope.visit.verifBeforeStart == -10) {
          $scope.redVerifBeforeStart = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }

        if ($scope.visit.boardDocument && $scope.visit.boardDocument == 2) {
          $scope.greenBoardDocument = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.boardDocument && $scope.visit.boardDocument == -4) {
          $scope.orangeBoardDocument = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.boardDocument && $scope.visit.boardDocument == -10) {
          $scope.redBoardDocument = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }

        if ($scope.visit.merchandiseStowageAndLashing && $scope.visit.merchandiseStowageAndLashing == 2) {
          $scope.greenMerchandiseStowageAndLashing = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.merchandiseStowageAndLashing && $scope.visit.merchandiseStowageAndLashing == -4) {
          $scope.orangeMerchandiseStowageAndLashing = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.merchandiseStowageAndLashing && $scope.visit.merchandiseStowageAndLashing == -10) {
          $scope.redMerchandiseStowageAndLashing = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.assetInAndOut && $scope.visit.assetInAndOut == 2) {
          $scope.greenAssetInAndOut = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.assetInAndOut && $scope.visit.assetInAndOut == -4) {
          $scope.orangeAssetInAndOut = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.assetInAndOut && $scope.visit.assetInAndOut == -10) {
          $scope.redAssetInAndOut = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.drivingPosition && $scope.visit.drivingPosition == 2) {
          $scope.greenDrivingPosition = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.drivingPosition && $scope.visit.drivingPosition == -4) {
          $scope.orangeDrivingPosition = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.drivingPosition && $scope.visit.drivingPosition == -10) {
          $scope.redDrivingPosition = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.seatbelt && $scope.visit.seatbelt == 2) {
          $scope.greenSeatbelt = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.seatbelt && $scope.visit.seatbelt == -4) {
          $scope.orangeSeatbelt = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.seatbelt && $scope.visit.seatbelt == -10) {
          $scope.redSeatbelt = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.SteeringWheel && $scope.visit.SteeringWheel == 2) {
          $scope.greenSteeringWheel = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.SteeringWheel && $scope.visit.SteeringWheel == -4) {
          $scope.orangeSteeringWheel = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.SteeringWheel && $scope.visit.SteeringWheel == -10) {
          $scope.redSteeringWheel = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.reportChoice && $scope.visit.reportChoice == 2) {
          $scope.myGreenReportChoice = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.reportChoice && $scope.visit.reportChoice == -4) {
          $scope.myOrangeReportChoice = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.reportChoice && $scope.visit.reportChoice == -10) {
          $scope.myRedReportChoice = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.clutchPedal && $scope.visit.clutchPedal == 2) {
          $scope.myGreenclutchPedal = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.clutchPedal && $scope.visit.clutchPedal == -4) {
          $scope.myOrangeclutchPedal = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.clutchPedal && $scope.visit.clutchPedal == -10) {
          $scope.myRedclutchPedal = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.floorRelay && $scope.visit.floorRelay == 2) {
          $scope.myGreenfloorRelay = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.floorRelay && $scope.visit.floorRelay == -4) {
          $scope.myOrangefloorRelay = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.floorRelay && $scope.visit.floorRelay == -10) {
          $scope.myRedfloorRelay = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.moderator && $scope.visit.moderator == 2) {
          $scope.myGreenmoderator = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.moderator && $scope.visit.moderator == -4) {
          $scope.myOrangemoderator = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.moderator && $scope.visit.moderator == -10) {
          $scope.myRedmoderator = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.mainBrake && $scope.visit.mainBrake == 2) {
          $scope.myGreenmainBrake = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.mainBrake && $scope.visit.mainBrake == -4) {
          $scope.myOrangemainBrake = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.mainBrake && $scope.visit.mainBrake == -10) {
          $scope.myRedmainBrake = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.deliveryPosition && $scope.visit.deliveryPosition == 2) {
          $scope.myGreendeliveryPosition = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.deliveryPosition && $scope.visit.deliveryPosition == -4) {
          $scope.myOrangedeliveryPosition = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.deliveryPosition && $scope.visit.deliveryPosition == -10) {
          $scope.myReddeliveryPosition = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.manoeuvres && $scope.visit.manoeuvres == 2) {
          $scope.myGreenmanoeuvres = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.manoeuvres && $scope.visit.manoeuvres == -4) {
          $scope.myOrangemanoeuvres = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.manoeuvres && $scope.visit.manoeuvres == -10) {
          $scope.myRedmanoeuvres = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.parkVehicle && $scope.visit.parkVehicle == 2) {
          $scope.myGreenparkVehicle = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.parkVehicle && $scope.visit.parkVehicle == -4) {
          $scope.myOrangeparkVehicle = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.parkVehicle && $scope.visit.parkVehicle == -10) {
          $scope.myRedparkVehicle = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.normalCirculation && $scope.visit.normalCirculation == 2) {
          $scope.myGreennormalCirculation = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.normalCirculation && $scope.visit.normalCirculation == -4) {
          $scope.myOrangenormalCirculation = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.normalCirculation && $scope.visit.normalCirculation == -10) {
          $scope.myRednormalCirculation = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.longitudinalSpacing && $scope.visit.longitudinalSpacing == 2) {
          $scope.myGreenlongitudinalSpacing = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.longitudinalSpacing && $scope.visit.longitudinalSpacing == -4) {
          $scope.myOrangelongitudinalSpacing = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.longitudinalSpacing && $scope.visit.longitudinalSpacing == -10) {
          $scope.myRedlongitudinalSpacing = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.lateralSpacing && $scope.visit.lateralSpacing == 2) {
          $scope.myGreenlateralSpacing = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.lateralSpacing && $scope.visit.lateralSpacing == -4) {
          $scope.myOrangelateralSpacing = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.lateralSpacing && $scope.visit.lateralSpacing == -10) {
          $scope.myRedlateralSpacing = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.trajectoryHold && $scope.visit.trajectoryHold == 2) {
          $scope.myGreentrajectoryHold = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.trajectoryHold && $scope.visit.trajectoryHold == -4) {
          $scope.myOrangetrajectoryHold = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.trajectoryHold && $scope.visit.trajectoryHold == -10) {
          $scope.myRedtrajectoryHold = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.crossings && $scope.visit.crossings == 2) {
          $scope.myGreencrossings = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.crossings && $scope.visit.crossings == -4) {
          $scope.myOrangecrossings = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.crossings && $scope.visit.crossings == -10) {
          $scope.myRedcrossings = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.overtakings && $scope.visit.overtakings == 2) {
          $scope.myGreenovertakings = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.overtakings && $scope.visit.overtakings == -4) {
          $scope.myOrangeovertakings = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.overtakings && $scope.visit.overtakings == -10) {
          $scope.myRedovertakings = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.preselection && $scope.visit.preselection == 2) {
          $scope.myGreenpreselection = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.preselection && $scope.visit.preselection == -4) {
          $scope.myOrangepreselection = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.preselection && $scope.visit.preselection == -10) {
          $scope.myRedpreselection = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.wayPosition && $scope.visit.wayPosition == 2) {
          $scope.myGreenwayPosition = {"background-color": "green"};
          $scope.count += 2; $scope.countGreen += 2;
        }
        if ($scope.visit.wayPosition && $scope.visit.wayPosition == -4) {
          $scope.myOrangewayPosition = {"background-color": "orange"};
          $scope.count += -4; $scope.countOrange += -4;
        }
        if ($scope.visit.wayPosition && $scope.visit.wayPosition == -10) {
          $scope.myRedwayPosition = {"background-color": "red"};
          $scope.count += -10; $scope.countRed += -10;
        }
        if ($scope.visit.roundaboutPosition && $scope.visit.roundaboutPosition == 2) {
          $scope.myGreenroundaboutPosition = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.roundaboutPosition && $scope.visit.roundaboutPosition == -4) {
          $scope.myOrangeroundaboutPosition = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.roundaboutPosition && $scope.visit.roundaboutPosition == -10) {
          $scope.myRedroundaboutPosition = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.rightTurn && $scope.visit.rightTurn == 2) {
          $scope.myGreenrightTurn = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.rightTurn && $scope.visit.rightTurn == -4) {
          $scope.myOrangerightTurn = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.rightTurn && $scope.visit.rightTurn == -10) {
          $scope.myRedrightTurn = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.leftTurn && $scope.visit.leftTurn == 2) {
          $scope.myGreenleftTurn = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.leftTurn && $scope.visit.leftTurn == -4) {
          $scope.myOrangeleftTurn = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.leftTurn && $scope.visit.leftTurn == -10) {
          $scope.myRedleftTurn = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.stopRoadwayUse && $scope.visit.stopRoadwayUse == 2) {
          $scope.myGreenstopRoadwayUse = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.stopRoadwayUse && $scope.visit.stopRoadwayUse == -4) {
          $scope.myOrangestopRoadwayUse = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.stopRoadwayUse && $scope.visit.stopRoadwayUse == -10) {
          $scope.myRedstopRoadwayUse = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.speedRace && $scope.visit.speedRace == 2) {
          $scope.myGreenspeedRace = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.speedRace && $scope.visit.speedRace == -4) {
          $scope.myOrangespeedRace = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.speedRace && $scope.visit.speedRace == -10) {
          $scope.myRedspeedRace = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.generalObserving && $scope.visit.generalObserving == 2) {
          $scope.myGreengeneralObserving = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.generalObserving && $scope.visit.generalObserving == -4) {
          $scope.myOrangegeneralObserving = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.generalObserving && $scope.visit.generalObserving == -10) {
          $scope.myRedgeneralObserving = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.rightMirror && $scope.visit.rightMirror == 2) {
          $scope.myGreenrightMirror = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.rightMirror && $scope.visit.rightMirror == -4) {
          $scope.myOrangerightMirror = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.rightMirror && $scope.visit.rightMirror == -10) {
          $scope.myRedrightMirror = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.leftMirror && $scope.visit.leftMirror == 2) {
          $scope.myGreenleftMirror = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.leftMirror && $scope.visit.leftMirror == -4) {
          $scope.myOrangeleftMirror = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.leftMirror && $scope.visit.leftMirror == -10) {
          $scope.myRedleftMirror = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.intersectionsControl && $scope.visit.intersectionsControl == 2) {
          $scope.myGreenintersectionsControl = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.intersectionsControl && $scope.visit.intersectionsControl == -4) {
          $scope.myOrangeintersectionsControl = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.intersectionsControl && $scope.visit.intersectionsControl == -10) {
          $scope.myRedintersectionsControl = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.decelerationsControl && $scope.visit.decelerationsControl == 2) {
          $scope.myGreendecelerationsControl = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.decelerationsControl && $scope.visit.decelerationsControl == -4) {
          $scope.myOrangedecelerationsControl = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.decelerationsControl && $scope.visit.decelerationsControl == -10) {
          $scope.myReddecelerationsControl = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.footPositionForBrakes && $scope.visit.footPositionForBrakes == 2) {
          $scope.myGreenfootPositionForBrakes = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.footPositionForBrakes && $scope.visit.footPositionForBrakes == -4) {
          $scope.myOrangefootPositionForBrakes = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.footPositionForBrakes && $scope.visit.footPositionForBrakes == -10) {
          $scope.myRedfootPositionForBrakes = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }

        if ($scope.visit.manoeuvresObserving && $scope.visit.manoeuvresObserving == 2) {
          $scope.myGreenmanoeuvresObserving = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.manoeuvresObserving && $scope.visit.manoeuvresObserving == -4) {
          $scope.myOrangemanoeuvresObserving = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.manoeuvresObserving && $scope.visit.manoeuvresObserving == -10) {
          $scope.myRedmanoeuvresObserving = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.directionChangeWarning && $scope.visit.directionChangeWarning == 2) {
          $scope.myGreendirectionChangeWarning = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.directionChangeWarning && $scope.visit.directionChangeWarning == -4) {
          $scope.myOrangedirectionChangeWarning = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.directionChangeWarning && $scope.visit.directionChangeWarning == -10) {
          $scope.myReddirectionChangeWarning = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.dislocationWarning && $scope.visit.dislocationWarning == 2) {
          $scope.myGreendislocationWarning = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.dislocationWarning && $scope.visit.dislocationWarning == -4) {
          $scope.myOrangedislocationWarning = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.dislocationWarning && $scope.visit.dislocationWarning == -10) {
          $scope.myReddislocationWarning = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.overtakingWarning && $scope.visit.overtakingWarning == 2) {
          $scope.myGreenovertakingWarning = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.overtakingWarning && $scope.visit.overtakingWarning == -4) {
          $scope.myOrangeovertakingWarning = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.overtakingWarning && $scope.visit.overtakingWarning == -10) {
          $scope.myRedovertakingWarning = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }

        if ($scope.visit.harshDecelerationWarning && $scope.visit.harshDecelerationWarning == 2) {
          $scope.myGreenharshDecelerationWarning = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.harshDecelerationWarning && $scope.visit.harshDecelerationWarning == -4) {
          $scope.myOrangeharshDecelerationWarning = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.harshDecelerationWarning && $scope.visit.harshDecelerationWarning == -10) {
          $scope.myRedharshDecelerationWarning = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }

        if ($scope.visit.manoeuvresWarning && $scope.visit.manoeuvresWarning == 2) {
          $scope.myGreenmanoeuvresWarning = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.manoeuvresWarning && $scope.visit.manoeuvresWarning == -4) {
          $scope.myOrangemanoeuvresWarning = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.manoeuvresWarning && $scope.visit.manoeuvresWarning == -10) {
          $scope.myRedmanoeuvresWarning = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }

        if ($scope.visit.vehicleLightUseWarning && $scope.visit.vehicleLightUseWarning == 2) {
          $scope.myGreenvehicleLightUseWarning = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.vehicleLightUseWarning && $scope.visit.vehicleLightUseWarning == -4) {
          $scope.myOrangevehicleLightUseWarning = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.vehicleLightUseWarning && $scope.visit.vehicleLightUseWarning == -10) {
          $scope.myRedvehicleLightUseWarning = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }

        if ($scope.visit.givewayToPedestrian && $scope.visit.givewayToPedestrian == 2) {
          $scope.myGreengivewayToPedestrian = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.givewayToPedestrian && $scope.visit.givewayToPedestrian == -4) {
          $scope.myOrangegivewayToPedestrian = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.givewayToPedestrian && $scope.visit.givewayToPedestrian == -10) {
          $scope.myRedgivewayToPedestrian = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.givewayToVehicles && $scope.visit.givewayToVehicles == 2) {
          $scope.myGreengivewayToVehicles = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.givewayToVehicles && $scope.visit.givewayToVehicles == -4) {
          $scope.myOrangegivewayToVehicles = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.givewayToVehicles && $scope.visit.givewayToVehicles == -10) {
          $scope.myRedgivewayToVehicles = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.givewayUnjustified && $scope.visit.givewayUnjustified == 2) {
          $scope.myGreengivewayUnjustified = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.givewayUnjustified && $scope.visit.givewayUnjustified == -4) {
          $scope.myOrangegivewayUnjustified = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.givewayUnjustified && $scope.visit.givewayUnjustified == -10) {
          $scope.myRedgivewayUnjustified = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
        if ($scope.visit.verticalSignalingRespect && $scope.visit.verticalSignalingRespect == 2) {
          $scope.myGreenverticalSignalingRespect = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.verticalSignalingRespect && $scope.visit.verticalSignalingRespect == -4) {
          $scope.myOrangeverticalSignalingRespect = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.verticalSignalingRespect && $scope.visit.verticalSignalingRespect == -10) {
          $scope.myRedverticalSignalingRespect = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }

        if ($scope.visit.horizontalSignalingRespect && $scope.visit.horizontalSignalingRespect == 2) {
          $scope.myGreenhorizontalSignalingRespect = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.horizontalSignalingRespect && $scope.visit.horizontalSignalingRespect == -4) {
          $scope.myOrangehorizontalSignalingRespect = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.horizontalSignalingRespect && $scope.visit.horizontalSignalingRespect == -10) {
          $scope.myRedhorizontalSignalingRespect = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }

        if ($scope.visit.airLiquideRegulationRespect && $scope.visit.airLiquideRegulationRespect == 2) {
          $scope.myGreenairLiquideRegulationRespect = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.airLiquideRegulationRespect && $scope.visit.airLiquideRegulationRespect == -4) {
          $scope.myOrangeairLiquideRegulationRespect = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.airLiquideRegulationRespect && $scope.visit.airLiquideRegulationRespect == -10) {
          $scope.myRedairLiquideRegulationRespect = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }

        if ($scope.visit.countryRegulationRespect && $scope.visit.countryRegulationRespect == 2) {
          $scope.myGreencountryRegulationRespect = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.countryRegulationRespect && $scope.visit.countryRegulationRespect == -4) {
          $scope.myOrangecountryRegulationRespect = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.countryRegulationRespect && $scope.visit.countryRegulationRespect == -10) {
          $scope.myRedcountryRegulationRespect = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }

        if ($scope.visit.protectionGlassesHeadpiece && $scope.visit.protectionGlassesHeadpiece == 2) {
          $scope.myGreenprotectionGlassesHeadpiece = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.protectionGlassesHeadpiece && $scope.visit.protectionGlassesHeadpiece == -4) {
          $scope.myOrangeprotectionGlassesHeadpiece = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.protectionGlassesHeadpiece && $scope.visit.protectionGlassesHeadpiece == -10) {
          $scope.myRedprotectionGlassesHeadpiece = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }

        if ($scope.visit.workGlovesAndClothing && $scope.visit.workGlovesAndClothing == 2) {
          $scope.myGreenworkGlovesAndClothing = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.workGlovesAndClothing && $scope.visit.workGlovesAndClothing == -4) {
          $scope.myOrangeworkGlovesAndClothing = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.workGlovesAndClothing && $scope.visit.workGlovesAndClothing == -10) {
          $scope.myRedworkGlovesAndClothing = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }

        if ($scope.visit.safetyShoes && $scope.visit.safetyShoes == 2) {
          $scope.myGreensafetyShoes = {"background-color": "green"};
          $scope.count += 2;
          $scope.countGreen += 2;
        }
        if ($scope.visit.safetyShoes && $scope.visit.safetyShoes == -4) {
          $scope.myOrangesafetyShoes = {"background-color": "orange"};
          $scope.count += -4;
          $scope.countOrange += -4;
        }
        if ($scope.visit.safetyShoes && $scope.visit.safetyShoes == -10) {
          $scope.myRedsafetyShoes = {"background-color": "red"};
          $scope.count += -10;
          $scope.countRed += -10;
        }
      }
    }
  });

  $scope.printSheet = function (printSectionId) {
    var innerContents = document.getElementById(printSectionId).innerHTML;
    var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');

    if (popupWinindow) {
      popupWinindow.document.open();
      popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
      popupWinindow.document.close();
    } else {
      var innerContents = document.getElementById(printSectionId).innerHTML;
      var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
      popupWinindow.document.open();
      popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
      popupWinindow.document.close();
    }
  };


    $scope.exportToExcel = function () {
        // ex: '#my-table'
        var nom='ficheDriverVisit';
        var tableId='#driverVisitTable';

        $timeout(function () {

            $scope.exportHref = Excel.tableToExcel(tableId,nom);
        }, 100);

        $timeout(function () {
            //location.href = $scope.exportHref;
            var a = document.createElement('a');
            a.href = $scope.exportHref;
            a.download = nom+'-'+moment().format('YYYY-MM-DD-HHmmSS')+'.xls';
            a.click();
        }, 100);



    };
});
