/**
 * Created by Hamdi on 18/12/2017.
 */

angular.module('app').controller('vehicleCtrlSheetCtrl', function ($scope, $translate, SafeToLoadUserSrv,
                                                                   SafeToLoadVehicleCtrlSheetSrv, SafeToLoadCompanySrv, SafeToLoadAssetSrv,SafeToLoadSiteSrv, ngDialog, Notification) {
  $scope.itemsByPage = 5;
  $scope.search = {};

  SafeToLoadCompanySrv.query({}, 100, 1, function (response) {
    if (response.success) {
      $scope.companies = response.result;
    }
  });
  SafeToLoadAssetSrv.query({}, 1000, 1, function (response) {
    if (response.success) {
      $scope.assets = response.result;
    }
  });
    SafeToLoadSiteSrv.query({}, 1000, 1, function (response) {
        if (response.success) {
            $scope.sites = response.result;
        }
    });
  SafeToLoadUserSrv.query({}, 100, 1, function (response) {
    if (response.success) {
      $scope.users = response.result;
    }
  });

  $scope.loadSheets = function (tableState, ctrl) {
    if (!$scope.stCtrl && ctrl) {
      $scope.stCtrl = ctrl;
    }

    if (!tableState && $scope.stCtrl) {
      $scope.stCtrl.pipe();
      return;
    }
    var start = 0;
    var number = $scope.itemsByPage;

    var options = {
      populate: [
        {
          path: '_company_owner',
          select: 'name'

        },
        {
          path: '_created_by',
          select: 'first_name last_name'
        },
        {
          path: '_asset',
          select: 'name'
        } ,
          {
              path: '_site',
              select: 'name'
          }
      ],
        sortBy: {creation_dt: -1}

    };

    var query = {};

    if (tableState.pagination) {
      var pagination = tableState.pagination;
      number = pagination.number || $scope.itemsByPage;
      start = pagination.start / number + 1 || 1;
    }
    if (tableState.sort && tableState.sort.predicate) {
      options.sortBy = {};

      var predicate = tableState.sort.predicate;
      options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
    }

    if (tableState.search && tableState.search.predicateObject) {
      if (tableState.search.predicateObject.place) {
        query.place = {$regex: tableState.search.predicateObject.place, $options: "i"};
      }
    }

    if ($scope.search && $scope.search._company_owner) {
      query._company_owner = $scope.search._company_owner;
    }
    if ($scope.search && $scope.search._created_by) {
      query._created_by = $scope.search._created_by;
    }
    if ($scope.search && $scope.search._asset) {
      query._asset = $scope.search._asset;
    }
       // console.log("sheets function 2 :",options)

    SafeToLoadVehicleCtrlSheetSrv.query({query: query, options: options}, number, start, function (response) {
      if (response.success) {
        $scope.sheets = response.result;

             //   console.log("sheets listt :",$scope.sheets)

        $scope.sheetCount = response.total_count;
        tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
        tableState.pagination.totalItemCount = response.total_count;
        $scope.tableState = tableState;
      }
    });
  };

  $scope.remove = function (sheet) {
    ngDialog.openConfirm({template: 'app/commons/dialogs/confirm.dlg.html', overlay: true, showClose: false, controller: ['$scope', function ($scope) {
            // controller logic
      $scope.title = $translate.instant("SHEET_DELETE_CONFIRM_TITLE");
      $scope.message = $translate.instant("SHEET_DELETE_CONFIRM_MSG", {asset: sheet._asset ? sheet._asset.name : null});
    }]}).then(function (value) {
      SafeToLoadVehicleCtrlSheetSrv.delete(sheet._id, function (data) {
        if (data.success) {
          Notification($translate.instant("SHEET_DELETED", {asset: sheet._asset ? sheet._asset.name : null}));
          $scope.loadSheets();
          $scope.tableState.pagination.start = 0;
        } else {
          console.log(data.error);
        }
      }, function (err) {

      });
    }, function (reason) {

    });
  };
});
