angular.module('app').controller('adminSubConstractorEditCtrl', function ($scope, $rootScope, $state, $translate, SafeToLoadSubConstractorSrv, $stateParams,ngDialog, Notification) {

    $scope.subConstractor = {};
    $scope.addNewContact = function () {
        ngDialog.open({
            template: 'app/commons/dialogs/subConstContact.dlg.tpl.html',
            controller: 'addSubConstContactDlgCtrl',
            scope:$scope
        });
    };

    /*Remove contacts*/
    $scope.deleteContact = function(contact){
        var index = $scope.subConstractor.contacts.indexOf(contact);
        if (index > -1) {
            $scope.subConstractor.contacts.splice(index, 1);
        }
    };
    var query = {
        query: {
            _id: $stateParams.subconstractorId
        },
        options: {
            populate: [
                {
                    path: '_company_owner',
                    select: 'name'
                }
            ]

        }
    };
    SafeToLoadSubConstractorSrv.query(query, 1, 1, function (response) {
        $scope.subConstractor = response.result[0];
    }, function (err) {
        console.log(err);
    });


    $scope.save = function () {
        //$scope.subconstractor._company_owner = $rootScope.SESSION.user._company_owner._id;
        SafeToLoadSubConstractorSrv.updateById($scope.subConstractor._id,$scope.subConstractor, function (data) {
            if (data.success) {
                Notification.success({
                    title : $translate.instant("UPDATE"),
                    message : $translate.instant("ALERT_SUB_CONSTRACTOR_UPDATED",{name:$scope.subConstractor.name})
                });
                $state.go('app.admin.subconstractors');
                $scope.subConstractor  = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.subConstractor.name +"!"
                });
                $scope.subConstractor  = {};
            }
        }, function (data) {
            console.log(data);
        });
    }

});