angular.module('app').controller('adminSubConstractorAddCtrl', function ($scope, $rootScope, $state, $translate, SafeToLoadSubConstractorSrv, ngDialog, Notification) {
    $scope.subConstractor  = {};
    $scope.addNewContact = function () {
        ngDialog.open({
            template: 'app/commons/dialogs/subConstContact.dlg.tpl.html',
            controller: 'addSubConstContactDlgCtrl',
            scope:$scope
        });
    };

    /*Remove checkers*/
    $scope.deleteContact = function(contact){
        var index = $scope.subConstractor.contacts.indexOf(contact);
        if (index > -1) {
            $scope.subConstractor.contacts.splice(index, 1);
        }
    };

    $scope.save = function () {
        //$scope.subConstractor._company_owner = $rootScope.SESSION.user._company_owner._id;
        SafeToLoadSubConstractorSrv.add($scope.subConstractor, function (data) {
            if (data.success) {
                Notification.success({
                    title : $translate.instant("ADD"),
                    message : $translate.instant("ALERT_SUB_CONSTRACTOR_ADDED",{name:$scope.subConstractor.name})
                });

                $state.go('app.admin.subconstractors');

            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.subConstractor.name +"!"
                });
                $scope.subConstractor  = {};
            }
        }, function (data) {
            console.log(data);
        });
    }

})