angular.module('app').controller('adminDriverCtrl', function ($scope, $rootScope, $state, $translate, SafeToLoadDriverSrv, ngDialog, Notification) {
  $scope.driver = {};
  $scope.itemsByPage = 5;
  $scope.search = {};

  $scope.loadDrivers = function (tableState, ctrl) {
    if (!$scope.stCtrl && ctrl) {
      $scope.stCtrl = ctrl;
    }

    if (!tableState && $scope.stCtrl) {
      $scope.stCtrl.pipe();

      return;
    }
    var start = 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
    var number = $scope.itemsByPage;
    var query = {
      options: {populate:
      [
        {
          path: '_company_owner',
          select: 'name'
        },
        {
          path: '_asset',
          select: 'name'
        },
        {
          path: '_ctry',
          select: 'name'

        },
          {
              path: '_site',
              select: 'name'
          }  ,
          {
              path: '_sub_constractor',
              select: 'name'
          }

      ]

      }
    };
    if (tableState.pagination) {
      var pagination = tableState.pagination;
      number = pagination.number || $scope.itemsByPage;
      start = pagination.start / number + 1 || 1;
    }
    if (tableState.sort && tableState.sort.predicate) {
      query.options.sortBy = {};

      var predicate = tableState.sort.predicate;
      query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
    }

    var requery = {};
    if (tableState.search && tableState.search.predicateObject) {
      if (tableState.search.predicateObject.first_name) {
        requery.first_name = {$regex: tableState.search.predicateObject.first_name, $options: "i"};
      }
      if (tableState.search.predicateObject.last_name) {
        requery.last_name = {$regex: tableState.search.predicateObject.last_name, $options: "i"};
      }
      if (tableState.search.predicateObject._asset) {
        requery._asset = {$regex: tableState.search.predicateObject._asset, $options: '-i'};
      }
    }
    if ($scope.search) {
      if ($scope.search._company_owner) {
        requery._company_owner = $scope.search._company_owner;
      }
      if ($scope.search._site) {
        requery._site = $scope.search._site;
      }
        if ($scope.search._sub_constractor) {
            requery._sub_constractor = $scope.search._sub_constractor;
        }
        if ($scope.search.country) {
            requery._ctry = $scope.search.country;
        }
    }
    query.query = requery;

    SafeToLoadDriverSrv.query(query, number, start, function (data) {
      if (data.success) {
        $scope.drivers = data.result;
        $scope.driverCount = data.total_count;
        tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
        tableState.pagination.totalItemCount = data.total_count;
        $scope.tableState = tableState;
      } else {
        console.error(data.error);
      }
    }, function (data) {
      console.log(data);
    });
  };
    /* delete asset*/
  $scope.remove = function (driver) {
    ngDialog.openConfirm({template: 'app/commons/dialogs/confirm.dlg.html', overlay: true, showClose: false, controller: ['$scope', function ($scope) {
                // controller logic
      $scope.title = $translate.instant("DRIVER_DELETE_CONFIRM_TITLE", {name: driver.first_name});
      $scope.message = $translate.instant("DRIVER_DELETE_CONFIRM_MESSAGE", {name: driver.first_name});
    }]}).then(function () {
      SafeToLoadDriverSrv.delete(driver._id, function (data) {
        if (data.success) {
          Notification($translate.instant("ALERT_DRIVER_DELETED", {name: driver.first_name}));
          $scope.loadDrivers();
          $scope.tableState.pagination.start = 0;
        } else {
          console.log(data.error);
        }
      }, function (err) {

      });
    }, function (reason) {

    });
  };
});
