/**
 * Project: SafeToLoad
 * Created by  YOUSFI houcine 19/12/2017.
 */
angular.module("app.services").factory('constantSrv', function () {
  var service = {};

  service.driverSafetyProperties = function () {
    return ['airLiquideRegulationRespect', 'assetInAndOut', 'boardDocument', 'clutchPedal', 'countryRegulationRespect', 'crossings', 'decelerationsControl', 'deliveryPosition',
      'directionChangeWarning', 'dislocationWarning', 'drivingPosition', 'floorRelay', 'footPositionForBrakes', 'generalObserving', 'givewayToPedestrian', 'givewayToVehicles',
      'givewayUnjustified', 'harshDecelerationWarning', 'horizontalSignalingRespect', 'intersectionsControl', 'lateralSpacing', 'leftMirror', 'leftTurn', 'longitudinalSpacing',
      'mainBrake', 'manoeuvres', 'manoeuvresObserving', 'manoeuvresWarning', 'merchandiseStowageAndLashing', 'moderator', 'normalCirculation', 'overtakingWarning', 'SteeringWheel',
      'overtakings', 'parkVehicle', 'preselection', 'protectionGlassesHeadpiece', 'reportChoice', 'rightMirror', 'rightTurn', 'roundaboutPosition', 'safetyShoes', 'seatbelt',
      'speedRace', 'stopRoadwayUse', 'trajectoryHold', 'vehicleLightUseWarning', 'verifBeforeStart', 'verticalSignalingRespect', 'wayPosition', 'workGlovesAndClothing'
    ];
  };
  service.assetControlProperties = function () {
    return ['trainingCertificateCopy', 'emergencyInterventionPlan', 'displayedSecuritySheet', 'roadmap', 'medicalAptitudeCertificate', 'accidentReportingForm',
       'validGrayCard', 'validInsurance', 'validTechnicalVisit', 'insuranceAirLiquidProduct',
      'trafficTaxProofOfPayment', 'twoKiloExtinguisherPtacLess',  'sixKiloExtinguisher', 'firstAidKit',
      'toolbox', 'wheelChocks', 'selfSupportingWarningSignals', 'fluorescentJacketForAll', 'flameproofFlashLight',
       'ninetySpeedLimiter', 'forkliftStatus', 'driverBehaviorControlDevice', 'seatbelt', 'absBrakingSystem', 'intactMirrors', 'mudguard', 'reflectiveTape', 'noSmokingPanels',
      'dangerSignalPanels', 'securedCharge', 'goodConditionTire', 'tachograph', 'antiPulloutSystem', 'goodLightingSystem', 'cleanAsset', 'DriverStatus',
      'dangerousMaterialCarryingTraining', 'defensiveDriveTrainingLastFive', 'productMarkingTraining', 'loadLimitationTraining',
      'epiWearing'

    ];
  };
    service.assetRequiredControlProperties = function () {
        return ['trainingCertificateCopy', 'emergencyInterventionPlan', 'displayedSecuritySheet', 'roadmap', 'medicalAptitudeCertificate', 'accidentReportingForm'

        ];
    };

  service.dailyCheckProperties = function () {
    return {notUsed: ['brakingSystem', 'trailerLink', 'lightingSystem', 'wheelControl', 'seatbelt', 'regressWarning', 'productSafetyControl', 'tools'],
      canUsed: ['oilLevel', 'engineCoolingProductLevel', 'wiper', 'battery', 'spareWheel']};
  };

  service.getBrands = function () {
    return [
      {
        id: "ABARTHA",
        name: "Abartha"
      },
      {
        id: "ALFA_ROMEO",
        name: "Alfa Romeo"
      },
      {
        id: "ASTON_MARTIN",
        name: "Aston Martin"
      },
      {
        id: "AUDI",
        name: "Audi"
      },
      {
        id: "BENTLEY",
        name: "Bentley"
      },
      {
        id: "BMW",
        name: "BMW"
      },
      {
        id: "BUGATTI",
        name: "Bugatti"
      },
      {
        id: "CADILLAC",
        name: "Cadillac"
      },
      {
        id: "CAPARO",
        name: "Caparo"
      },
      {
        id: "CATERHAM",
        name: "Caterham"
      },
      {
        id: "CATERPILLAR",
        name: "Caterpillar"
      },
      {
        id: "CHEVROLET",
        name: "Chevrolet"
      },
      {
        id: "CHRYSLER",
        name: "Chrysler"
      },
      {
        id: "CITROEN",
        name: "Citroën"
      },
      {
        id: "CORVETTE",
        name: "Corvette"
      },
      {
        id: "DAF",
        name: "DAF"
      },
      {
        id: "DACIA",
        name: "Dacia"
      },
      {
        id: "DAEWOO",
        name: "Daewoo"
      },
      {
        id: "DAIHATSU",
        name: "Daihatsu"
      },
      {
        id: "DATSUN",
        name: "Datsun"
      },
      {
        id: "DELOREAN",
        name: "Delorean"
      },
      {
        id: "DODGE",
        name: "Dodge"
      },
      {
        id: "ARASH",
        name: "Arash"
      },
      {
        id: "FERRARI",
        name: "Ferrari"
      },
      {
        id: "FIAT",
        name: "Fiat"
      },
      {
        id: "FISKER",
        name: "Fisker"
      },
      {
        id: "FORD",
        name: "Ford"
      },
      {
        id: "HONDA",
        name: "Honda"
      },
      {
        id: "HUMMER",
        name: "Hummer"
      },
      {
        id: "HYUNDAI",
        name: "Hyundai"
      },
      {
        id: "INFINITI",
        name: "Infiniti"
      },
      {
        id: "IVECO",
        name: "Iveco"
      },
      {
        id: "ISUZU",
        name: "Isuzu"
      },
      {
        id: "JAGUAR",
        name: "Jaguar"
      },
      {
        id: "JCB",
        name: "JCB"
      },
      {
        id: "JEEP",
        name: "Jeep"
      },
      {
        id: "KIA",
        name: "KIA"
      },
      {
        id: "KTM",
        name: "KTM"
      },
      {
        id: "KOENIGSEGG",
        name: "Koenigsegg"
      },
      {
        id: "LADA",
        name: "Lada"
      },
      {
        id: "LAMBORGHINI",
        name: "Lamborghini"
      },
      {
        id: "LANCIA",
        name: "Lancia"
      },
      {
        id: "LAND_ROVER",
        name: "Land Rover"
      },
      {
        id: "LEXUS",
        name: "Lexus"
      },
      {
        id: "LIGIER",
        name: "Ligier"
      },
      {
        id: "LINCOLN",
        name: "Lincoln"
      },
      {
        id: "LOTUS",
        name: "Lotus"
      },
      {
        id: "MAHINDRA",
        name: "Mahindra"
      },
      {
        id: "MARTINI",
        name: "Martini"
      },
      {
        id: "MAN",
        name: "MAN"
      },
      {
        id: "MASERATI",
        name: "Maserati"
      },
      {
        id: "MAYBACH",
        name: "Maybach"
      },
      {
        id: "MAZDA",
        name: "Mazda"
      },
      {
        id: "MCLAREN",
        name: "MCLaren"
      },
      {
        id: "MERCEDES",
        name: "Mercedes"
      },
      {
        id: "MG",
        name: "MG"
      },
      {
        id: "MINI",
        name: "Mini"
      },
      {
        id: "MITSUBISHI",
        name: "Mitsubishi"
      },
      {
        id: "MORGAN",
        name: "Morgan"
      },
      {
        id: "NISSAN",
        name: "Nissan"
      },
      {
        id: "NOBLE",
        name: "Noble"
      },
      {
        id: "OM",
        name: "OM"
      },
      {
        id: "OPEL",
        name: "Opel"
      },
      {
        id: "PAGANI",
        name: "Pagani"
      },
      {
        id: "PEUGEOT",
        name: "Peugeot"
      },
      {
        id: "PONTIAC",
        name: "Pontiac"
      },
      {
        id: "PORSCHE",
        name: "Porsche"
      },
      {
        id: "PROTON",
        name: "Proton"
      },
      {
        id: "RENAULT",
        name: "Renault"
      },
      {
        id: "ROLLS_ROYCE",
        name: "Rolls Royce"
      },
      {
        id: "ROVER",
        name: "Rover"
      },
      {
        id: "SAAB",
        name: "Saab"
      },
      {
        id: "SSANGYONG",
        name: "SsangYong"
      },
      {
        id: "SEAT",
        name: "Seat"
      },
      {
        id: "SCANIA",
        name: "Scania"
      },
      {
        id: "SKODA",
        name: "Skoda"
      },
      {
        id: "SMART",
        name: "Smart"
      },
      {
        id: "SPYKER",
        name: "Spyker"
      },
      {
        id: "SUBARU",
        name: "Subaru"
      },
      {
        id: "SUZUKI",
        name: "Suzuki"
      },
      {
        id: "TATA",
        name: "TATA"
      },
      {
        id: "TOYOTA",
        name: "Toyota"
      },
      {
        id: "VAUXHALL",
        name: "Vauxhall"
      },
      {
        id: "VOLKSWAGEN",
        name: "Volkswagen"
      },
      {
        id: "VOLVO",
        name: "Volvo"
      },
      {
        id: "WALLYSCAR",
        name: "Wallyscar"
      },
      {
        id: "OTHERS",
        name: "Autres"
      }
    ];
  };

  return service;
});
