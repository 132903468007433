/**
 * Project: SafeToLoad
 * Created by  YOUSFI houcine 28/05/2018.
 */

angular.module('app').controller('statisticCtrl', function ($scope, $rootScope,$state, SafeToLoadAssetSrv,SafeToLoadDriverSrv,SafeToLoadSiteSrv,SafeToLoadSubConstractorSrv) {
    SafeToLoadAssetSrv.query({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.assets = response.result;
        }
    });
    SafeToLoadDriverSrv.query({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.drivers = response.result;
        }
    });
    SafeToLoadSubConstractorSrv.query({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.suppliers = response.result;
        }
    });
    SafeToLoadSiteSrv.query({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.sites = response.result;
        }
    });
    $scope.aTypes =["ASSETS","SUPPLIERS","SITES"];
    $scope.dTypes =["DRIVERS","SUPPLIERS","SITES"];

});
