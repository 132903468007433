/**
 * Project: SafeToLoad
 * Created by  YOUSFI houcine 18/12/2017.
 */
angular.module('app').controller('dashDriverCtrl', function ($scope, $rootScope, $state, SafeToLoadDriverSrv, SafeToLoadDriverSafetyVisitSrv,ngDialog, constantSrv, $timeout, $translate) {
  $scope.driverProperties = constantSrv.driverSafetyProperties();
  $scope.loadDriversList = function () {
    $scope.driversIds = [];
    SafeToLoadDriverSrv.query({}, 1000, 0, function (response) {
      if (response.success) {
        $scope.driversList = response.result;
        angular.forEach($scope.driversList, function (driver) {
          $scope.driversIds.push(driver._id);
        });
        $scope.loadDriverSafetyVisitList();
      }
    });
  };
  $scope.loadDriverSafetyVisitList = function () {
    $scope.getQuery = function () {
      return {
        aggregate: [
          {
            $match: {
              _driver: {
                $in: $scope.driversIds.map(function (elem) {
                  return "#OBJID#" + elem + "#OBJIDEND#";
                })
              }
            }
          },

                    {$sort: {creation_dt: -1}},
          {
            $project: {
              _driver: 1,
              document: "$$ROOT"
            }
          },
          {
            $group: {
              _id: '$_driver',

              data: {
                $push: {
                  properties: "$document"
                }
              }
            }
          },
          {
            $project: {
              _id: '$_id',
              data: {
                $arrayElemAt: ["$data", 0]
              }
            }
          }

        ]
      };
    };

    $scope.driverScores = [];
    $scope.driverNames = [];
    $scope.levelScores = [];

    SafeToLoadDriverSafetyVisitSrv.agg($scope.getQuery(), 10000, 0, function (response) {
      if (response.success) {
        $scope.driverSafetyVisitList = response.result;
        $scope.getDriverScores();
      }
    });
  };
  $scope.loadDriversList();

    // $scope.loadDriverSafetyVisitList();

  $scope.getDriverScores = function () {
  /*  $scope.driverLessBad = 0;
    $scope.driverLessAvg = 0;
    $scope.driverLessGood = 0;*/
      $scope.driverBadList =[];
      $scope.driverAvgList = [];
      $scope.driverGoodList = [];
    $scope.driversMap = {};
    $scope.driversList.map(function (driver) {
      $scope.driversMap[driver._id] = driver;
    });
    $scope.driverSafetyVisitList.map(function (elem) {
      var driverElem = $scope.driversMap[elem._id]
      $scope.driverNames.push(driverElem.last_name + " " + driverElem.first_name);
      var score = 0;
      for (var prop in elem.data.properties) {
        if ($scope.driverProperties.indexOf(prop) != -1) {
          score += elem.data.properties[prop];
        }
      }
      if (score < 50) {
          $scope.driverBadList.push(driverElem)
        $scope.driverScores.push({y: score, color: '#ED561B'});
      } else if (score >= 50 && score < 70) {
          $scope.driverAvgList.push(driverElem)
          $scope.driverScores.push({y: score, color: '#FFA500'});
      } else if (score >= 70) {
          $scope.driverGoodList.push(driverElem)
        $scope.driverScores.push({y: score, color: '#50B432'});
      }
    });

    $scope.getLevelSeriesData();
    $scope.chartLoad();
  };
  $scope.getLevelSeriesData = function () {
    $scope.levelScores.push([$translate.instant("DRIVER_SCORE_50"), $scope.driverBadList.length]);
    $scope.levelScores.push([$translate.instant("DRIVER_SCORE_50_70"), $scope.driverAvgList.length]);
    $scope.levelScores.push([$translate.instant("DRIVER_SCORE_70"), $scope.driverGoodList.length]);
  };





$scope.getDriversDetails = function(drivers) {

if(drivers && drivers.length>0) {
    ngDialog.open({
        template: 'app/commons/dialogs/drivers.info.dlg.html',
        controller: 'driversInfoCtrl',
        resolve: {
            drivers: function () {
                return drivers;
            }
        }
    })
}
}
  $scope.chartLoad = function () {
    $timeout(function () {
      Highcharts.chart("pieScore", $scope.levelChartConfig).reflow();
      Highcharts.chart("driverScore", $scope.scoreChartConfig).reflow();
    });
    $scope.scoreChartConfig = {
      options: {
        chart: {
          type: 'column',
          backgroundColor: 'transparent'
        }
      },
      series: [{
        type: "column",
        name: $translate.instant("SCORE"),
        data: $scope.driverScores,
        dataLabels: {
          enabled: true,
          color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || '#0067c1'

        }
      }],
      yAxis: {
        title: {
          text: $translate.instant("SCORE")
        }

      },
      xAxis: {
        categories: $scope.driverNames
      },

      title: {
        text: $translate.instant("DRIVERS_SCORES")
      },
      subtitle: {
        text: ""
      }

    };
    $scope.levelChartConfig = {
      options: {
        chart: {
          type: 'pie',

          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false
        }
      },
      plotOptions: {
        pie: {
          colors: [
            '#ED561B',
            '#FFA500',
            '#50B432'
          ],
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          }
        }
      },

      series: [{
        type: 'pie',
        name: $translate.instant("NUMBER"),
        data: $scope.levelScores,
        color: '#0079e3',
        dataLabels: {
          enabled: true,
          color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || '#0067c1'

        }
      }],
      title: {
        text: $translate.instant("DRIVERS_PERCENTAGE")
      },
      subtitle: {
        text: ""
      }

    };
  };
});
