/**
 * Project: SafeToLoad
 * Created by  YOUSFI houcine 28/05/2018.
 */

angular.module('app').controller('statisticSidebarCtrl', function ($scope, $rootScope, $state) {
  $scope.statisticMenu = [];
  $scope.loadStatisticMenu = function () {
    var tab = [
      {
        name: "ASSETS_CONTROL",
        permission: $rootScope.checkPermission('VEHICLE_CONTROL_SHEET', 'R'),
        route: 'app.statistic.control',
        icon: "fa fa-car"
      },
      {
        name: "ASSET_DAILY_CONTROL",
        permission: $rootScope.checkPermission('DAILY_CHECKLIST', 'R'),
        route: 'app.statistic.daily',
        icon: 'fa fa-calendar-check-o'
      },
      {
        name: "DRIVERS_SECURITY",
        permission: $rootScope.checkPermission('DRIVER_SAFETY_VISIT', 'R'),
        route: 'app.statistic.drivers',
        icon: 'fa fa-user-circle'
      }

    ];
    angular.forEach(tab, function (item) {
      if (item.permission) {
        $scope.statisticMenu.push(item);
      }
    });
    if ($scope.statisticMenu && $scope.statisticMenu.length > 0) {
      $state.go($scope.statisticMenu[0].route);
    }
  };
  $scope.loadStatisticMenu();
});
